<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Gate Pass</h4>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <div class="row m-0">
                                <div class="col-md-6 col-6">
                                    <div class="d-flex justify-content-between align-items-center ml-1">
                                        <label class="d-flex align-items-center">Show
                                            <select class="form-control mx-25" [(ngModel)]="lengths"
                                                (change)="loadPageLenghs($event)">

                                                <option value="30">30</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6 col-6 d-flex justify-content-start justify-content-md-end">
                                    <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pb-md-0 w-100">
                                        <button class="btn btn-primary ml-1" rippleEffect routerLink="/gate-pass/add">
                                            <i data-feather="plus"
                                                class="d-sm-none d-inline-block mr-0 mr-sm-1"></i><span
                                                class="d-none d-sm-inline-block">Add New</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col p-0 table-responsive">
                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Vendor</th>
                                            <th>Type</th>
                                            <th>Returnable</th>
                                            <th>Transport</th>
                                            <th>Remark</th>
                                            <th>Created</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="gatePass?.length != 0">
                                        <tr *ngFor="let gate of gatePass; let ndx = index">
                                            <td>{{ndx+1}}</td>
                                            <td>{{ gate.vendor_name }}</td>
                                            <td>{{ gate.is_product }}</td>
                                            <td>{{ gate.is_returnable }}</td>
                                            <td>{{ gate.transport }}</td>
                                            <td>{{ gate.remarks }}</td>
                                            <td>{{ gate.created }}</td>
                                            <td>
                                                <div ngbDropdown container="body">
                                                    <button type="button" class="btn btn-sm hide-arrow"
                                                        ngbDropdownToggle data-toggle="dropdown">
                                                        <i data-feather="more-vertical"></i>
                                                    </button>
                                                    <div ngbDropdownMenu>

                                                        <a ngbDropdownItem href="javascript:void(0);"
                                                            (click)="manageStock(gate)"><i data-feather="grid"
                                                                class="mr-50"></i><span>Stock Manage</span></a>

                                                        <a ngbDropdownItem href="javascript:void(0);"
                                                            (click)="modalOpen(modalBasic,gate)">
                                                            <i data-feather="eye" class="mr-50"></i>
                                                            <span>View Details</span>
                                                        </a>
                                                        <a ngbDropdownItem routerLink="/gate-pass/edit/{{ gate.id }}">
                                                            <i data-feather="edit-2" class="mr-50"></i>
                                                            <span>Edit</span></a>
                                                        <a ngbDropdownItem href="javascript:void(0);"
                                                            (click)="delete(gate.id)">
                                                            <i data-feather="trash" class="mr-50"></i>
                                                            <span>Delete</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="gatePass?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="7" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="7" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="d-flex justify-content-end mt-2 mr-2" *ngIf="collectionSize > 0"
                                    [collectionSize]="collectionSize" [pageSize]="lengths" [(page)]="pageBasicText"
                                    (pageChange)="loadPage($event)" aria-label="Custom pagination">
                                    <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'"
                                            [class]="'font-weight-bolder'"></span>Prev
                                    </ng-template>
                                    <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'"
                                            [class]="'font-weight-bolder'"></span>
                                    </ng-template>
                                </ngb-pagination>

                                <!-- Modal -->
                                <ng-template #modalBasic let-modal>
                                    <div class="modal-header">
                                        <h4 class="modal-title" id="myModalLabel1">
                                            Gate Pass Details <b *ngIf="userDetails.id"># {{ userDetails.id }}</b></h4>
                                        <button type="button" class="close" (click)="modal.dismiss('Cross click')"
                                            aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body" tabindex="0" ngbAutofocus>
                                        <div class="row">
                                            <div class="col-4 mb-1 border-bottom">
                                                <div class="">
                                                    <b>Vendor : </b> {{ userDetails.vendor_name }}
                                                </div>
                                            </div>
                                            <div class="col-4 mb-1 border-bottom">
                                                <div class="">
                                                    <b>Type : </b>{{ userDetails.is_product }}
                                                </div>
                                            </div>
                                            <div class="col-4 mb-1 border-bottom">
                                                <div class="">
                                                    <b>Returnable : </b>{{ userDetails.is_returnable }}
                                                </div>
                                            </div>
                                            <div class="col-4 mb-1 border-bottom">
                                                <div class="">
                                                    <b>Transport : </b>{{ userDetails.transport }}
                                                </div>
                                            </div>


                                        </div>

                                        <div class="row table-responsive">
                                            <table class="table table-hover table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Product Details</th>
                                                        <th>Expected Arrival Date</th>
                                                        <th>Received</th>
                                                        <th>Remaining</th>
                                                        <th>Quantity</th>
                                                        <th>Status</th>
                                                        <th>comments</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let itm of userDetails.gate_pass_products; let i = index;">
                                                        <td>{{i+1}}</td>
                                                        <td *ngIf="itm.product_details">
                                                            {{itm.product_details}}
                                                        </td>
                                                        <td *ngIf="itm.product_id">
                                                            {{itm.product.product_code}}
                                                            {{itm.product.product_name}}
                                                            {{itm.product.size_title}}
                                                            {{itm.product.color_title}}
                                                        </td>
                                                        <td>
                                                            {{itm.expected_arrival_date | date:'dd-MM-YYYY'}}
                                                        </td>
                                                        <td>{{itm.receive_qty}}</td>
                                                        <td>{{itm.remaining_qty}}</td>
                                                        <td>{{itm.product_qty}}</td>
                                                        <td>
                                                            <span
                                                                [ngClass]="itm.status == '1' ? 'badge badge-warning' : 'badge badge-success'">{{(itm.status=='1'?'Open':'Close')}}</span>
                                                        </td>
                                                        <td>{{itm.comments || 'N/A'}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="col-12 my-1">
                                                <b>Remarks : </b>{{ userDetails.remarks }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary"
                                            (click)="modal.close('Accept click')" rippleEffect>
                                            Close
                                        </button>
                                    </div>
                                </ng-template>
                                <!-- / Modal -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>



<!-- New User Sidebar -->
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="new-sidebar" overlayClass="modal-backdrop">
    <div class="slideout-content">
        <!-- Modal to add new starts-->
        <div class="modalsd modal-slide-in sdfade new-modal" id="modals-slide-in">
            <div class="modal-dialog modal-xl">
                <form class="add-new modal-content pt-0" [formGroup]="newFormStock" (ngSubmit)="onSubmitStock()">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="toggleSidebar('new-sidebar')">
                        ×
                    </button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title" id="exampleModalLabel">Return Product</h5>
                    </div>
                    <div class="modal-body flex-grow-1 ">

                        <div class="row m-0" *ngIf="userDetails">
                            <div class="col">
                                <div class="form-group">
                                    <label for="product_id" class="form-label">Select Product<span
                                            class="text-danger">*</span></label>
                                    <select #productData formControlName="product_id" class="form-control"
                                        aria-describedby="product_id" tabindex="14"
                                        [ngClass]="{ 'is-invalid': submittedStock && fs.product_id.errors }"
                                        (change)="onSelectProduct(productData)">
                                        <option value="" selected>Select Product</option>
                                        <ng-container
                                            *ngFor="let gpp of userDetails.gate_pass_products; let i = index;">
                                            <ng-container *ngIf="gpp.product_details  && gpp.remaining_qty > 0 && gpp.status == 1 && gpp.expected_arrival_date">
                                                <option [attr.data-gate_pass_product_id]="gpp.id" value="0">
                                                    {{gpp.product_details}}</option>
                                            </ng-container>
                                            <ng-container *ngIf="gpp.product_id && gpp.remaining_qty > 0 && gpp.status == 1">
                                                <option [attr.data-gate_pass_product_id]="gpp.id"
                                                    [attr.data-gate_pass_data]="gpp | json" value="{{gpp.product_id}}">
                                                    {{gpp.product.product_code}}
                                                    {{gpp.product.product_name}}
                                                    {{gpp.product.size_title}}
                                                    {{gpp.product.color_title}}
                                                </option>
                                            </ng-container>
                                        </ng-container>
                                    </select>
                                    <div *ngIf="submittedStock && fs.product_id.errors" class="invalid-feedback">
                                        <div *ngIf="fs.product_id.errors.required">Stock is required</div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="register-receive_qty" class="form-label">Stock Receive Add<span
                                            class="text-danger">*</span></label>
                                    <input type="number" class="form-control" formControlName="receive_qty"
                                        placeholder="Stock Add" aria-describedby="register-receive_qty"
                                        [ngClass]="{ 'is-invalid': submittedStock && fs.receive_qty.errors }" />
                                    <div *ngIf="submittedStock && fs.receive_qty.errors" class="invalid-feedback">
                                        <div *ngIf="fs.receive_qty.errors.required">Stock is required</div>
                                        <div *ngIf="fs.receive_qty.errors.max">Quantity Mismatch</div>
                                    </div>
                                </div>

                                <button type="submit" class="btn btn-primary mr-1 " rippleEffect
                                    [disabled]="loadingFrmStock"> <span *ngIf="loadingFrmStock "
                                        class="spinner-border spinner-border-sm mr-1 "></span>Submit</button>
                                <button type="reset" class="btn btn-secondary" (click)="toggleSidebar('new-sidebar')"
                                    [disabled]="loadingFrmStock" rippleEffect>
                                    <span *ngIf="loadingFrmStock" class="spinner-border spinner-border-sm mr-1"></span>
                                    <i data-feather="x" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                    <span class="d-none d-sm-inline-block">Cancel </span>
                                </button>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-12 p-0 table-responsive">
                                <div class="overlay" *ngIf="progressStockTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th> Product</th>
                                            <th> Quantity</th>
                                            <th> Added</th>
                                            <!-- <th> #</th> -->
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="stockData?.length != 0">
                                        <tr *ngFor="let productStock of stockData; let ndx = index">


                                            <td *ngIf="productStock.gate_pass_product.product_details">
                                                {{productStock.gate_pass_product.product_details}}
                                            </td>
                                            <td *ngIf="productStock.gate_pass_product.product_id">
                                                {{productStock.gate_pass_product.product.product_code}}
                                                {{productStock.gate_pass_product.product.product_name}}
                                                {{productStock.gate_pass_product.product.size_title}}
                                                {{productStock.gate_pass_product.product.color_title}}
                                            </td>
                                            <td>{{ productStock.receive_qty }}</td>
                                            <td>{{ productStock.created_at | date:'dd-MM-YYYY' }}</td>
                                            <!-- <td></td> -->
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="stockData?.length == 0">
                                        <tr *ngIf="!loadingStock">
                                            <td colspan="3" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" class="no-data-available">
                                                <div class="col" *ngIf="loadingStock">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                
                                <ngb-pagination class="d-flex justify-content-end mt-2 mr-2" *ngIf="collectionSizeStock > 0"
                                    [collectionSize]="collectionSizeStock" [pageSize]="lengthsStock" [(page)]="pageStockBasicText"
                                    (pageChange)="loadStockPage($event)" aria-label="Custom pagination">
                                    <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'"
                                            [class]="'font-weight-bolder'"></span>Prev
                                    </ng-template>
                                    <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'"
                                            [class]="'font-weight-bolder'"></span>
                                    </ng-template>
                                </ngb-pagination>

                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </div>
        <!-- Modal to add new Ends-->
    </div>
</core-sidebar>
<!--/ New User Sidebar -->