import { Component, OnInit } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import Swal from 'sweetalert2';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'app/auth/service';
import { Router } from '@angular/router';
import { PurchaseOrderService } from 'app/_services/purchase-order.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { PurchaseRequisitionService } from 'app/_services/purchase-requisition.service';
const now = new Date();

@Component({
  selector: 'app-purchase-requisition',
  templateUrl: './purchase-requisition.component.html',
  styleUrls: ['./purchase-requisition.component.scss']
})
export class PurchaseRequisitionComponent implements OnInit {

  public rowMaterialProductsDropDown: any = [];

  public loading = false;
  public addMode = false;
  public error = '';
  public newForm: FormGroup;
  public submitted = false;
  public loadingForm = false;
  public purchaseRequisitionId: any = 0;

  public currentUser: any;
  public currentUserRole = '';

  public polist: boolean = true;
  public makepo: boolean = false;
  public finalPO: boolean = false;

  public basicDPdata: any = [];

  //public basicDPdatanew: NgbDateStruct = { year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate() };

  public start: any = 0;
  public page: any = 0;
  public lengths = 30;
  public pageAdvancedEllipses = 1;
  public progressMainTable: boolean = false;
  public requisitionList: any = [];
  public makepoList: any = [];
  public pageBasicText = 1;
  public collectionSize = 0;

  public isSelected: boolean = false;
  public isAllSelected: boolean = false;
  public checkedIDs: any = [];
  public makeFinalPO: any = [];
  public finalData: any = [];
  public tempData: any = [];

  public showProductName:boolean = false;
  public showRowProductName:boolean = true;

  public loadingNon = false;
  public startNon: any = 0;
  public pageNon: any = 0;
  public lengthsNon = 30;
  public pageAdvancedEllipsesNon = 1;
  public progressMainTableNon: boolean = false;
  public requisitionListNon: any = [];
  public makePoListNon: any = [];
  public pageBasicTextNon = 1;
  public collectionSizeNon = 0;
  public loadingFormNon = false;
  
  public polistNon: boolean = true;
  public makepoNon: boolean = false;
  public finalPONon: boolean = false;
  public isSelectedNon: boolean = false;
  public isAllSelectedNon: boolean = false;
  public checkedIDsNon: any = [];
  public makeFinalListNon: any = [];
  public makeFinalPONon: any = [];
  public vendorList: any = [];
  public tempVendorNon: any = [];
  public finalDataNon: any = [];
  public tempDataNon: any = [];
  public active = 1;

  constructor(private _router: Router, private _authenticationService: AuthenticationService, private _toastrService: ToastrService, private http: HttpClient, private purchaseOrderService: PurchaseOrderService, private purchaseRequisitionService: PurchaseRequisitionService, private _coreSidebarService: CoreSidebarService, private _formBuilder: FormBuilder) {
    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));
    this.currentUserRole = JSON.parse(localStorage.getItem('currentUser')).user.role;
  }

  ngOnInit(): void {
    this.loading = true;
    this.getRowMaterialProductsDropDowns(0);
    this.getDataList(this.start, this.lengths);
    this.getDataListNon(this.startNon, this.lengthsNon);
    this.setActive(1);
    this.newForm = this._formBuilder.group({
      raw_material_product_id: ["", [Validators.required]],
      product_name: ["", [Validators.required]],
      price: ["", [Validators.required]],
      qty: ["", [Validators.required]],
      priority: ["", [Validators.required]],
    });

  }
  get f() {
    return this.newForm.controls;
  }

  getDataList(my_start: any, my_lengths: any) {
    this.purchaseRequisitionService.getData(my_start, my_lengths).subscribe(
      response => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          response.data.forEach((value, index) => {
            this.basicDPdata[index] = { year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate() };
          });
          this.requisitionList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  countChange(event, index) {
    this.makepoList[index].poQty = event;
  }

  reloadList() {
    this.progressMainTable = true;
    this.getDataList(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.getDataList(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.getDataList(this.start, this.lengths);
  }


  getRowMaterialProductsDropDowns(i: any) {
    this.purchaseOrderService.getRowMaterialProductsDropDown('').subscribe(
      data => {
        if (data.status) {
          this.rowMaterialProductsDropDown = data.products;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }
 /**
 * Toggle the sidebar
 *
 * @param name
 */
  toggleSidebar(name, id: number = 0, index: any = null) {
    this.showProductName = false;
    this.showRowProductName = true;

    this.newForm.get('product_name').clearValidators();
    this.newForm.get('product_name').updateValueAndValidity();
    this.newForm.get('price').clearValidators();
    this.newForm.get('price').updateValueAndValidity();
    this.newForm.get('raw_material_product_id').setValidators(Validators.required);

    this.submitted = false;
    this.error = '';
    this.loadingForm = false;
    this.addMode = true;
    this.purchaseRequisitionId = 0;
    if (id != 0 && index != null) {
      this.newForm.reset();
      this.addMode = false;
      this.purchaseRequisitionId = id;
      this.f.raw_material_product_id.setValue(this.requisitionList[index].row_material_product_id);
      this.f.qty.setValue(this.requisitionList[index].qty);
      this.f.priority.setValue(this.requisitionList[index].priority);
    }
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  makePO() {
    this.polist = false;
    this.makepo = true;
    this.finalPO = false;
    this.loading = true;
    if (this.makepoList.length == 0) {
      this.purchaseRequisitionService.getDataMakePO().subscribe(
        response => {
          this.loading = false;
          if (response.status) {
            this.makepoList = response.data;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        }
      );
    }
  }

  canclePO() {
    this.polist = true;
    this.makepo = false;
    this.finalPO = false;
  }

  finalpo() {
    if (this.checkedIDs.length > 0) {
      this.makeFinalPO = [];
      this.checkedIDs.forEach((value, index) => {
        if (this.makepoList[value].vendor !== undefined) {
          this.makeFinalPO.push(this.makepoList[value]);
        }
      });
      if (this.makeFinalPO.length > 0) {
        this.polist = false;
        this.makepo = false;
        this.finalPO = true;
        this.loading = true;
        this.finalData = [];
        this.purchaseRequisitionService.getDataFinalPO(this.makeFinalPO).subscribe(
          response => {
            this.loading = false;
            if (response.status) {
              this.finalData = response.finalData;
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
          }
        );
      }
      else {
        this._toastrService.warning('Please Select Vendor', 'Warning', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    }
    else {
      this._toastrService.warning('Please Select At least one product', 'Warning', { toastClass: 'toast ngx-toastr', closeButton: true });
    }
  }

  deleteSingle(mIndex, sIndex) {
    this.finalData[mIndex].product.splice(sIndex, 1);
    if (this.finalData[mIndex].product.length == 0) {
      this.finalData.splice(mIndex, 1);
      if (this.finalData.length == 0) {
        this.canclefinalpo();
      }
    }
  }

  canclefinalpo() {
    if (this.makepoList.length != 0) {
      this.polist = false;
      this.makepo = true;
      this.finalPO = false;
    }
    else {
      this.polist = true;
      this.makepo = false;
      this.finalPO = false;
    }
  }

  savePO(mIndex) {
    this.loadingForm = true;
    this.purchaseRequisitionService.makePOfromPR(this.finalData[mIndex]).subscribe(
      data => {
        this.loadingForm = false;
        if (data.status) {
          this.finalData.splice(mIndex, 1);
          this.reloadList();
          if (this.finalData.length == 0) {
            this.canclePO();
          }
          this.makepoList = [];
          this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
        else {
          this.error = data.error;
          this._toastrService.error('Something went wrong', 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newForm.invalid) {

    }
    else {
      this.loadingForm = true;
      const formdata = new FormData();
      formdata.append('row_material_product_id', this.f.raw_material_product_id.value);
      formdata.append('qty', this.f.qty.value);
      formdata.append('priority', this.f.priority.value);
      formdata.append('id', this.purchaseRequisitionId);

      this.purchaseRequisitionService.add(formdata, this.purchaseRequisitionId).subscribe(
        data => {
          this.loadingForm = false;
          if (data.status) {
            this.purchaseRequisitionId = 0
            this.submitted = false;
            this.toggleSidebar('new-sidebar');
            this.newForm.reset();
            this.reloadList();
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
          else {
            this.error = data.error;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
    }
  }

  delete(id: any) {
    const me = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(function (result) {
      if (result.value) {
        me.purchaseRequisitionService.delete(id).subscribe(
          response => {
            if (response.status) {
              me.progressMainTable = true;
              me.getDataList(me.page, me.lengths);
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Purchase Requisition Deleted Successfully',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            } else {
              Swal.fire({
                icon: 'warning',
                title: 'Opps!',
                text: response.message,
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
          }
        );
      }
    });
  }

  changeStatus(id: any, event) {
    const me = this;
    console.log(event);
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(function (result) {
      if (result.value) {
        me.purchaseRequisitionService.changeStatus(id, event).subscribe(
          response => {
            if (response.status) {
              me.progressMainTable = true;
              me.getDataList(me.page, me.lengths);
              Swal.fire({
                icon: 'success',
                title: 'Status Changed!',
                text: 'Purchase Requisition Status Changed Successfully',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            } else {
              Swal.fire({
                icon: 'warning',
                title: 'Opps!',
                text: response.message,
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
          }
        );
      }
    });
  }

  onCheckboxAllChangeFn(event) {
    if (event.target.checked == true) {
      this.isSelected = true;
      this.makepoList.forEach((value, index) => {
        this.makepoList[index].isChecked = true;
      });
    }
    else {
      this.makepoList.forEach((value, index) => {
        this.makepoList[index].isChecked = false;
      });
    }
    this.filterResults();
  }

  filterResults() {
    this.isAllSelected = false;
    this.checkedIDs = []
    this.makepoList.forEach((value, index) => {
      if (value.isChecked) {
        this.checkedIDs.push(index);
      }
    });
  }


  /*Non Product PO Start*/

  toggleSidebarNon(name, id: number = 0, index: any = null) {
    this.showRowProductName = false;
    this.showProductName = true;
    
    this.newForm.get('raw_material_product_id').clearValidators();
    this.newForm.get('raw_material_product_id').updateValueAndValidity();
    this.newForm.get('product_name').setValidators(Validators.required);
    this.newForm.get('price').setValidators(Validators.required);

    this.submitted = false;
    this.error = '';
    this.loadingForm = false;
    this.addMode = true;
    this.purchaseRequisitionId = 0;
    if (id != 0 && index != null) {
      this.newForm.reset();
      this.addMode = false;
      this.purchaseRequisitionId = id;
      this.f.product_name.setValue(this.requisitionListNon[index].product_name);
      this.f.qty.setValue(this.requisitionListNon[index].qty);
      this.f.price.setValue(this.requisitionListNon[index].price);
      this.f.priority.setValue(this.requisitionListNon[index].priority);
    }
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  getDataListNon(my_start: any, my_lengths: any) {
    this.purchaseRequisitionService.nonGetData(my_start, my_lengths).subscribe(
      response => {
        this.loadingNon = false;
        this.progressMainTableNon = false;
        if (response.status) {
          response.data.forEach((value, index) => {
            this.basicDPdata[index] = { year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate() };
          });
          this.requisitionListNon = response.data;
          this.collectionSizeNon = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  reloadListNon() {
    this.progressMainTableNon = true;
    this.getDataListNon(this.pageNon, this.lengthsNon);
  }

  loadPageNon(event: any) {
    this.progressMainTableNon = true;
    this.pageNon = this.lengthsNon * (event - 1);
    this.getDataListNon(this.pageNon, this.lengthsNon);
  }

  loadPageLenghsNon(event: any) {
    this.progressMainTableNon = true;
    this.lengthsNon = event.target.value;
    this.getDataListNon(this.startNon, this.lengthsNon);
  }

  onNonSubmit() {
    this.submitted = true;
    if (this.newForm.invalid) {
      this.loadingForm = false;
    }else {
      this.loadingForm = true;
      const formdata = new FormData();
      formdata.append('product_name', this.f.product_name.value);
      formdata.append('qty', this.f.qty.value);
      formdata.append('priority', this.f.priority.value);
      formdata.append('price', this.f.price.value);
      formdata.append('id', this.purchaseRequisitionId);

      this.purchaseRequisitionService.nonAdd(formdata, this.purchaseRequisitionId).subscribe(
        data => {
          this.loadingForm = false;
          if (data.status) {
            this.purchaseRequisitionId = 0
            this.submitted = false;
            this.toggleSidebarNon('new-sidebar');
            this.newForm.reset();
            this.reloadListNon();
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
          else {
            this.error = data.error;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
    }
  }

  deleteNon(id: any) {
    const me = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(function (result) {
      if (result.value) {
        me.purchaseRequisitionService.deleteNon(id).subscribe(
          response => {
            if (response.status) {
              me.progressMainTable = true;
              me.getDataListNon(me.page, me.lengths);
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Purchase Requisition Deleted Successfully',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            } else {
              Swal.fire({
                icon: 'warning',
                title: 'Opps!',
                text: response.message,
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
          }
        );
      }
    });
  }

  makePONon() {
    this.polist = false;
    this.makepo = false;
    this.finalPO = false;

    this.makepoNon = true;
    this.finalPONon = false
    this.loading = true;
    if (this.makeFinalListNon.length == 0) {
      this.purchaseRequisitionService.getDataMakePONon().subscribe(
        response => {
          this.loading = false;
          if (response.status) {
            this.makeFinalListNon = response.data;
            this.vendorList = response.vendors;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        }
      );
    }
  }

  changeStatusNon(id: any, event) {
    const me = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(function (result) {
      if (result.value) {
        me.purchaseRequisitionService.changeStatusNon(id, event).subscribe(
          response => {
            if (response.status) {
              me.progressMainTable = true;
              me.getDataListNon(me.page, me.lengths);
              Swal.fire({
                icon: 'success',
                title: 'Status Changed!',
                text: 'Purchase Requisition Status Changed Successfully',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            } else {
              Swal.fire({
                icon: 'warning',
                title: 'Opps!',
                text: response.message,
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
          }
        );
      }
    });
  }

  onCheckboxAllChangeFnNon(event) {
    if (event.target.checked == true) {
      this.isSelectedNon = true;
      this.makeFinalListNon.forEach((value, index) => {
        this.makeFinalListNon[index].isChecked = true;
      });
    }
    else {
      this.makeFinalListNon.forEach((value, index) => {
        this.makeFinalListNon[index].isChecked = false;
      });
    }
    this.filterResultsNon();
  }

  filterResultsNon() {
    this.isAllSelectedNon = false;
    this.checkedIDsNon = []
    this.makeFinalListNon.forEach((value, index) => {
      if (value.isChecked) {
        this.checkedIDsNon.push(index);
      }
    });
  }

  countChangeNon(event, index) {
    this.makeFinalListNon[index].poQty = event;
  }

  cancelPONon() {
    this.polist = true;
    this.makepo = false;
    this.finalPO = false;
    this.makepoNon = false;
    this.finalPONon = false
    this.setActive(2);
  }

  finalPoNon() {
    this.loadingNon = true;
    if (this.checkedIDsNon.length > 0) {
      this.finalDataNon = [];
      this.checkedIDsNon.forEach((value, index) => {
          if (this.makeFinalListNon[value].vendor !== undefined) {
            this.makeFinalPONon = []; // Initialize or reset the vendor list
            const vendorMap = new Map<number, any>();
            this.makeFinalListNon.forEach(product => {
              const vendorId = product.vendor;
              let vendor = vendorMap.get(vendorId);
              if (!vendor) {
                this.tempVendorNon = [];
                const vendors = this.vendorList; 
                vendors.forEach(vendor => {
                  if (vendor.id === vendorId) {
                    this.tempVendorNon.push({
                      id: vendor.id,
                      name: vendor.name || 'Unknown Vendor',
                      mobile: vendor.mobile || 'Unknown Mobile',
                      email: vendor.email || 'Unknown Email',
                      gst_no: vendor.gst_no || 'Unknown GST',
                      address: vendor.address || 'Unknown Address'
                    });
                  }
                });
                vendor = {
                  vendor: {
                    id: vendorId,
                    name: this.tempVendorNon[0].name || 'Unknown Vendor',
                    mobile: this.tempVendorNon[0].mobile || 'Unknown Mobile',
                    email: this.tempVendorNon[0].email || 'Unknown Email',
                    gst_no: this.tempVendorNon[0].gst_no || 'Unknown GST',
                    address: this.tempVendorNon[0].address || 'Unknown Address'
                  },
                  product: []
                };
                vendorMap.set(vendorId, vendor);
                this.makeFinalPONon.push(vendor); 
              }
              
              vendor.product.push({
                edate: product.date,
                po_qty: product.poQty,
                pr_id: product.id,
                vendor_id: vendorId,
                price: product.price || 'Not specified',
                product_name: product.product_name,
              });
            });
          }
      });

      if(this.makeFinalPONon.length > 0) {
        this.polist = false;
        this.makepo = false;
        this.finalPO = false;
        this.makepoNon = false;
        this.finalPONon = true
        this.loadingNon = false;
        this.finalDataNon = this.makeFinalPONon;
      }else {
        this._toastrService.warning('Please Select Vendor', 'Warning', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    }
    else {
      this._toastrService.warning('Please Select At least one product', 'Warning', { toastClass: 'toast ngx-toastr', closeButton: true });
    }
  }

  deleteSingleNon(mIndex, sIndex) {
    this.finalDataNon[mIndex].product.splice(sIndex, 1);
    if (this.finalDataNon[mIndex].product.length == 0) {
      this.finalDataNon.splice(mIndex, 1);
      if (this.finalDataNon.length == 0) {
        this.cancelFinalPoNon();
      }
    }
  }

  cancelFinalPoNon() {
    if (this.makeFinalListNon.length != 0) {
        this.polist = false;
        this.makepo = false;
        this.finalPO = false;
        this.makepoNon = true;
        this.finalPONon = false
    }else {
      this.polist = true;
      this.makepo = false;
      this.finalPO = false;
      this.finalPO = false;
      this.makepoNon = false;
      this.finalPONon = false
    }
  }

  savePONon(mIndex) {
    this.loadingFormNon = true;
    this.purchaseRequisitionService.makePOfromPRNon(this.finalDataNon[mIndex]).subscribe(
      data => {
        this.loadingFormNon = false;
        if(data.status) {
          this.finalDataNon.splice(mIndex, 1);
          this.reloadListNon();
          if (this.finalDataNon.length == 0) {
            this.cancelPONon();
          }
          this.makeFinalListNon = [];
          this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }else {
          this.error = data.error;
          this._toastrService.error('Something went wrong', 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      },err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
    });
  }

  setActive(tabId: number) {
    this.active = tabId;
  }
  /*Non Product PO End*/

}
