import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

const headers = new HttpHeaders({ 'Content-Type': 'application/json', responseType: 'blob' });

@Injectable({
  providedIn: 'root'
})


export class RowMaterialProductService {


  /**
   *
   * @param {HttpClient} _http
   */
  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  getData(start, length, searchByName): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-row-material-product`, { start, length, searchByName })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  delete(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}delete-row-material-product`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  add(categoryData: any, id: any): Observable<any> {
    if (id == 0) {
      return this._http
        .post(`${environment.apiUrl}save-row-material-product`, categoryData)
        .pipe(
          map(data => {
            return data;
          })
        );
    }
    else {
      return this._http
        .post(`${environment.apiUrl}update-row-material-product`, categoryData)
        .pipe(
          map(data => {
            return data;
          })
        );
    }
  }

  getRowMaterialProductCategoriesDropDown(): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}get-row-material-categories-dropdown`)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getVendors(): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}get-vendors-dropdown`)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getRowMaterialSubCategoriesList(category_id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-row-material-sub-categories-dropdown`, { category_id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getProducts(): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}get-manufacturer-products-list`)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getStockLog(start, length, searchByName, departmentId): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-department-wise-stock`, { start, length, searchByName, departmentId })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  downloadPdf(searchByName, departmentId): Observable<Blob> {
    return this._http.post<Blob>(`${environment.apiUrl}get-department-wise-stock-pdf`, { searchByName, departmentId }, {
      headers: headers, responseType:
        'blob' as 'json'
    });
  }
  downloadExcel(searchByName, departmentId): Observable<Blob> {
    return this._http.post<Blob>(`${environment.apiUrl}get-department-wise-stock-excel`, { searchByName, departmentId }, {
      headers: headers, responseType:
        'blob' as 'json'
    });
  }

}
