import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Directive, HostListener, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CoreConfigService } from '@core/services/config.service';
import { RegisterService } from '../../../_services/register.service';
import { CustomvalidationService } from 'app/_services/customvalidation.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class RegisterComponent implements OnInit {
  // Public
  public coreConfig: any;
  public passwordTextType: boolean;
  public passwordTextType1: boolean;
  public registerForm: FormGroup;
  public registerotpForm: FormGroup;
  public submitted = false;
  public loading = false;
  public loadingOtp = false;
  public otp_screen = false;
  public error = '';
  public otpmsg: any = '';
  public stateDropDown: any;
  public cityDropDown: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   * @param {FormBuilder} _formBuilder
   * @param {ToastrService} _toastrService
   */
  constructor(private el: ElementRef, private _coreConfigService: CoreConfigService, private customValidator: CustomvalidationService, private _toastrService: ToastrService, private _router: Router, private _formBuilder: FormBuilder, private registerService: RegisterService) {
    this._unsubscribeAll = new Subject();

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    };
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }

  get otp() {
    return this.registerotpForm.controls;
  }

  /**
   * Toggle password
   */
  togglePasswordTextType(me: any) {
    if (me == 0) {
      this.passwordTextType = !this.passwordTextType;
    }
    else {
      this.passwordTextType1 = !this.passwordTextType1;
    }
  }

  /**
   * On Submit
   */
  onSubmit() {
    this.error = '';
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {

    }
    else {
      this.loading = true;
      const { enterprise_name, phone, email, state_id, city_id, password } = this.registerForm.controls;
      this.registerService.add(enterprise_name.value, phone.value, email.value, state_id.value, city_id.value, password.value).subscribe(
        data => {
          if (data.status) {
            this.error = '';
            this.otp_screen = true;
            this._toastrService.success('OTP Send Successfully', '', { toastClass: 'toast ngx-toastr', closeButton: true });

          }
          else {
            this.loading = false;
            if (data.message.phone !== undefined) {
              this.error = data.message.phone[0];
            } else {
              this.error = data.message;
            }
          }

        });
    }
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.registerForm = this._formBuilder.group({
      enterprise_name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      owner_name: [''],
      state_id: ['', Validators.required],
      city_id: ['', Validators.required],
      password: ['', [Validators.required, Validators.compose([this.customValidator.patternValidator()])]],
      confirmPassword: ['', Validators.required],
      i_agree: ['', Validators.required],
    }, {
      validator: this.customValidator.MatchPassword('password', 'confirmPassword'),
    });

    this.registerotpForm = this._formBuilder.group({
      otp_one: ['', [Validators.required]],
      otp_two: ['', [Validators.required]],
      otp_three: ['', [Validators.required]],
      otp_four: ['', [Validators.required]],
      otp_five: ['', [Validators.required]],
      otp_six: ['', [Validators.required]]
    });

    this.getStateDropdown();

    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
  }

  getStateDropdown() {
    this.registerService.getState().subscribe(
      data => {
        if (data.status) {
          this.stateDropDown = data.states;
        }
      },
      err => {
       
      }
    );
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  getCity(event: any) {
    this.registerService.getCity(event.target.value).subscribe(
      data => {
        if (data.status) {
          this.cityDropDown = data.states;
        }
      },
      err => {
       
      }
    );
  }

  nextBox(event: any, next: any) {
    if (event.target.value != "" && event.target.value >= 0 && event.target.value <= 9 && next != "6") {
      const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + next + '"]');
      invalidControl.focus();
    }
  }

  onSubmitotp() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerotpForm.invalid) {

    }
    else {
      this.otpmsg = '';
      this.loadingOtp = true;
      this.otpmsg = this.otp.otp_one.value + '' + this.otp.otp_two.value + '' + this.otp.otp_three.value + '' + this.otp.otp_four.value + '' + this.otp.otp_five.value + '' + this.otp.otp_six.value;
      this.registerService.verifyotp(this.f.phone.value, this.otpmsg).subscribe(
        data => {
          this.loadingOtp = false;
          if (data.status) {
            setTimeout(() => {
              this._toastrService.success('You Have successfully registered',
                'Congratulations!',
                { toastClass: 'toast ngx-toastr', closeButton: true }
              );
            }, 500);
            this._router.navigate(['/login']);
          }
          else {
            this.error = data.message;
          }
        },
        err => {
         
        }
      );
    }
  }

  resendotp() {
    this.submitted = true;
    this.registerService.resendotp(this.f.phone.value).subscribe(
      data => {
        if (data.status) {
          this._toastrService.success(data.message, '', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
        else 
        {
          this._toastrService.error(data.message, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      },
      err => {
        
      }
    );

  }

}
