import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthLoginV2Component } from 'app/main/pages/authentication/auth-login-v2/auth-login-v2.component';
import { RegisterComponent } from 'app/main/pages/register/register.component';
import { AuthGuard } from './auth/helpers';
import { CategoryComponent } from './component/category/category.component';
import { HomeComponent } from './main/sample/home.component';
import { DealersComponent } from './component/dealers/dealers.component';
import { DealersAddComponent } from './component/dealers-add/dealers-add.component';
import { ProductsComponent } from './component/products/products.component';
import { ProductsAddComponent } from './component/products-add/products-add.component';
import { OrdersComponent } from './component/orders/orders.component';
import { OrdersAddComponent } from './component/orders-add/orders-add.component';
import { DeliveryChallanComponent } from './component/delivery-challan/delivery-challan.component';
import { DeliveryChallanAddComponent } from './component/delivery-challan-add/delivery-challan-add.component';
import { PricePerCustomerAddComponent } from './component/price-per-customer-add/price-per-customer-add.component';
import { PricePerCustomerComponent } from './component/price-per-customer/price-per-customer.component';
import { PricePerCustomerProductComponent } from './component/price-per-customer-product/price-per-customer-product.component';
import { ProfileComponent } from './component/profile/profile.component';
import { ForgotPasswordComponent } from 'app/main/pages/authentication/forgot-password/forgot-password.component';
import { ReportsComponent } from './component/reports/reports.component';
import { Role } from 'app/auth/models'
import { ManufacturersComponent } from './component/manufacturers/manufacturers.component';
import { EmployeeComponent } from './component/employee/employee.component';
import { EmployeeAddComponent } from './component/employee-add/employee-add.component';
import { ChallanReportComponent } from './component/challan-report/challan-report.component';
import { HelpComponent } from './component/help/help.component';
import { CustomerGuideComponent } from './component/customer-guide/customer-guide.component';
import { UnitsComponent } from './component/units/units.component';
import { OutstandingReportComponent } from './component/outstanding-report/outstanding-report.component';
import { PricingComponent } from './component/pricing/pricing.component';
import { SettingsComponent } from './component/settings/settings.component';
import { DepartmentsComponent } from './component/departments/departments.component';
import { VendorsComponent } from './component/vendors/vendors.component';
import { PurchaseOrderComponent } from './component/purchase-order/purchase-order.component';
import { PurchaseOrderAddComponent } from './component/purchase-order-add/purchase-order-add.component';
import { RowMaterialCategoryComponent } from './component/row-material-category/row-material-category.component';
import { RowMaterialProductComponent } from './component/row-material-product/row-material-product.component';
import { VendorCategoriesComponent } from './component/vendor-categories/vendor-categories.component';
import { VendorSubCategoriesComponent } from './component/vendor-sub-categories/vendor-sub-categories.component';
import { RowMaterialSubCategoriesComponent } from './component/row-material-sub-categories/row-material-sub-categories.component';
import { ProductMasterComboComponent } from './component/product-master-combo/product-master-combo.component';
import { ProductMasterComboAddComponent } from './component/product-master-combo-add/product-master-combo-add.component';
import { VendorAddComponent } from './component/vendor-add/vendor-add.component';
import { TransportMasterComponent } from './component/transport-master/transport-master.component';
import { OperatorMasterComponent } from './component/operator-master/operator-master.component';
import { MachineMasterComponent } from './component/machine-master/machine-master.component';
import { ProductionSubmitComponent } from './component/production-submit/production-submit.component';
import { WarehouseMasterComponent } from './component/warehouse-master/warehouse-master.component';
import { ProductionGivenComponent } from './component/production-given/production-given.component';
import { ProductWiseRawMaterialStockComponent } from './component/product-wise-raw-material-stock/product-wise-raw-material-stock.component';
import { StockTransferFinishedProductComponent } from './component/stock-transfer-finished-product/stock-transfer-finished-product.component';
import { StoreGivenComponent } from './component/store-given/store-given.component';
import { StoreReturnComponent } from './component/store-return/store-return.component';
import { PurchaseRequisitionComponent } from './component/purchase-requisition/purchase-requisition.component';
import { ManageComponent } from './component/manage/manage.component';
import { ToDoComponent } from './component/to-do/to-do.component';
import { PurchaseNonOrderAddComponent } from './component/purchase-non-order-add/purchase-non-order-add.component';

const routes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: AuthLoginV2Component,
    data: { animation: 'auth' }
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: { animation: 'auth' }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: { animation: 'auth' }
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client, Role.Admin, Role.GeneralManager, Role.Purchase, Role.Store, Role.Production] }
  },
  {
    path: 'categories',
    component: CategoryComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'dealers',
    component: DealersComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'dealers/add',
    component: DealersAddComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'dealers/edit/:id',
    component: DealersAddComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'products',
    component: ProductsComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'products/add',
    component: ProductsAddComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'products/edit/:id',
    component: ProductsAddComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'orders',
    component: OrdersComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client, Role.User] }
  },
  {
    path: 'orders/add',
    component: OrdersAddComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client, Role.User] }
  },
  {
    path: 'delivery-challan',
    component: DeliveryChallanComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'delivery-challan/add',
    component: DeliveryChallanAddComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'delivery-challan/add/:id',
    component: DeliveryChallanAddComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'price-per-customer',
    component: PricePerCustomerComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'price-per-customer/add',
    component: PricePerCustomerAddComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'price-per-customer/product/:id',
    component: PricePerCustomerProductComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'manufacturers',
    component: ManufacturersComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Admin] }
  },
  {
    path: 'employees',
    component: EmployeeComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'employees/add',
    component: EmployeeAddComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'employees/edit/:id',
    component: EmployeeAddComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'challan-reports',
    component: ChallanReportComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'supplier-guide',
    component: HelpComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'customer-guide',
    component: CustomerGuideComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'units',
    component: UnitsComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Admin] }
  },
  {
    path: 'outstanding-report',
    component: OutstandingReportComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'pricing',
    component: PricingComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'setting',
    component: SettingsComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'departments',
    component: DepartmentsComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'vendors',
    component: VendorsComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client, Role.Purchase] }
  },
  {
    path: 'vendor/edit/:id',
    component: VendorAddComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client, Role.Purchase] }
  },
  {
    path: 'purchase-order',
    component: PurchaseOrderComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client, Role.Purchase] }
  },
  {
    path: 'purchase-order/add',
    component: PurchaseOrderAddComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client, Role.Purchase] }
  },
  {
    path: 'purchase-non-order/add',
    component: PurchaseNonOrderAddComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client, Role.Purchase] }
  },
  {
    path: 'row-material-category',
    component: RowMaterialCategoryComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client, Role.Purchase] }
  },
  {
    path: 'row-material-sub-category',
    component: RowMaterialSubCategoriesComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client, Role.Purchase] }
  },
  {
    path: 'row-material-product',
    component: RowMaterialProductComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client, Role.Purchase, Role.Store] }
  },

  {
    path: 'vendor-category',
    component: VendorCategoriesComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client, Role.Purchase] }
  },
  {
    path: 'vendor-sub-category',
    component: VendorSubCategoriesComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client, Role.Purchase] }
  },
  {
    path: 'product-combo',
    component: ProductMasterComboComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client, Role.Store] }
  },
  {
    path: 'product-combo/add',
    component: ProductMasterComboAddComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'transport',
    component: TransportMasterComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client] }
  },
  {
    path: 'operator',
    component: OperatorMasterComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client, Role.Production] }
  },
  {
    path: 'machines',
    component: MachineMasterComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client, Role.Production] }
  },
  {
    path: 'production-submit',
    component: ProductionSubmitComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client, Role.Production, Role.Store, Role.Molding, Role.Tufting, Role.MoldingPlanning, Role.TuftingPlanning, Role.Planning,Role.PackagingAssembly] }
  },
  {
    path: 'warehouse',
    component: WarehouseMasterComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client, Role.Store] }
  },
  {
    path: 'production-given',
    component: ProductionGivenComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client, Role.Store, Role.Molding, Role.Tufting, Role.MoldingPlanning, Role.TuftingPlanning, Role.PackagingPlanning, Role.Planning,Role.PackagingAssembly,] }
  },
  {
    path: 'product-wise-rawmaterial-stock',
    component: ProductWiseRawMaterialStockComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client, Role.Store] }
  },
  {
    path: 'stock-transfer-finished-product',
    component: StockTransferFinishedProductComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client, Role.Store] }
  },
  {
    path: 'store-given-raw-material',
    component: StoreGivenComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client, Role.Store] }
  },
  {
    path: 'store-return-raw-material',
    component: StoreReturnComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client, Role.Store] }
  },
  {
    path: 'purchase-requisition',
    component: PurchaseRequisitionComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client, Role.Admin, Role.GeneralManager, Role.Purchase, Role.Store, Role.Production, Role.SalesMarketing, Role.Molding, Role.Tufting, Role.PackagingAssembly, Role.Dispatch, Role.HR, Role.Account, Role.MoldingPlanning, Role.TuftingPlanning, Role.PackagingPlanning, Role.Planning] }
  },
  {
    path: 'manage',
    component: ManageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.SalesMarketing, Role.Molding, Role.Tufting, Role.PackagingAssembly, Role.Dispatch, Role.HR, Role.Account, Role.MoldingPlanning, Role.TuftingPlanning, Role.PackagingPlanning, Role.Planning] }
  },
  {
    path: 'to-do',
    component: ToDoComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Production, Role.SalesMarketing, Role.Molding, Role.Tufting, Role.PackagingAssembly, Role.Store, Role.Dispatch] }
  },
  {
    path: '**',
    redirectTo: '/pages/miscellaneous/error' //Error 404 - Page not found
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    scrollPositionRestoration: 'enabled', //Add options right here
    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }