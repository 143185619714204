<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Product Combo List</h4>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <div class="row m-0">
                                <div class="col-md-3 col-6">
                                    <div class="d-flex justify-content-between align-items-center ml-1">
                                        <label class="d-flex align-items-center">Show
                                        <select class="form-control mx-25" [(ngModel)]="lengths" (change)="loadPageLenghs($event)">
                                            
                                            <option value="30">30</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                      </label>
                                    </div>
                                </div>
                                <div class="col-md-6 col-12 d-none d-sm-inline-block justify-content-start justify-content-md-end pb-1">
                                    <div class="d-flex align-items-end justify-content-end pr-1 pb-1 pb-md-0">

                                    </div>
                                </div>
                                <div class="col-md-3 col-6 d-flex justify-content-start justify-content-md-end pb-1">
                                    <!-- <div class="d-flex align-items-end justify-content-end pr-1 pb-1 pb-md-0">
                                        <button class="btn btn-primary ml-1" rippleEffect routerLink="/product-combo/add" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Add New Combo">
                                            <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block">Add New</span>
                                        </button>
                                    </div> -->
                                </div>

                            </div>
                            <div class="col p-0 table-responsive">
                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th class="action-column">#</th>
                                            <th> Name</th>
                                            <th> Size</th>
                                            <th> color</th>
                                            <th class="action-column"> View</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="productsComboList?.length != 0">
                                        <tr *ngFor="let product of productsComboList; let ndx = index">
                                            <td>{{ndx+page+1}}</td>
                                            <td>{{ product.product_code }} - {{ product.product_name }}</td>
                                            <td>{{ product.size_title }}</td>
                                            <td>{{ product.color_title }}</td>
                                            <td>
                                                <button type="button" (click)="modalOpen(modalBasic,product.id)" class="btn btn-xs btn-info">
                                                    <i data-feather="eye"></i>
                                                  </button>
                                            </td>


                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="productsComboList?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="8" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="8" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="mr-1 pull-right" [collectionSize]="collectionSize" *ngIf="collectionSize > lengths" [(page)]="pageBasicText" [maxSize]="5" (pageChange)="loadPage($event)" [pageSize]="lengths" [rotate]="true"></ngb-pagination>
                                <!-- <ngb-pagination class="d-flex justify-content-end mt-2 mr-2" *ngIf="collectionSize > 0" [collectionSize]="collectionSize" [pageSize]="lengths" [(page)]="pageBasicText" (pageChange)="loadPage($event)" aria-label="Custom pagination">
                                    <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'" [class]="'font-weight-bolder'"></span>Prev
                                    </ng-template>
                                    <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'" [class]="'font-weight-bolder'"></span>
                                    </ng-template>
                                </ngb-pagination> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>


<!-- Modal -->
<ng-template #modalBasic let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1" *ngIf="comboDetails?.length != 0">{{ comboDetails.name }} </h4>
        <h4 class="modal-title" id="myModalLabel1" *ngIf="comboDetails?.length == 0">Product Combo Details</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col" *ngIf="comboDetails?.length == 0">
            <div class="text-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div>
                    Please Wait...
                </div>
            </div>
        </div>
        <div class="row" *ngIf="comboDetails?.length != 0">
            <div class="col-12 pb-50">
                <h5 class=""> {{ comboDetails.full_name }}</h5>
            </div>
            <div class="col-12 pb-50 table-responsive">
                <table class="table table-border table-hover table-striped">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Raw Material Product</th>
                            <th>Use Qty</th>
                            <th>Current Stock</th>
                            <th>MOQ Level</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let comboMetaRawMaterialProduct of comboDetails.comboProducts; let ndx = index">
                            <td [ngClass]="{ 'text-danger': (comboMetaRawMaterialProduct.current_stock <= comboMetaRawMaterialProduct.moq_level) }">{{ndx+1}}</td>
                            <td [ngClass]="{ 'text-danger': (comboMetaRawMaterialProduct.current_stock <= comboMetaRawMaterialProduct.moq_level) }">{{comboMetaRawMaterialProduct.product_name}}</td>
                            <td [ngClass]="{ 'text-danger': (comboMetaRawMaterialProduct.current_stock <= comboMetaRawMaterialProduct.moq_level) }">{{comboMetaRawMaterialProduct.useQuantity}}</td>
                            <td [ngClass]="{ 'text-danger': (comboMetaRawMaterialProduct.current_stock <= comboMetaRawMaterialProduct.moq_level) }">{{comboMetaRawMaterialProduct.current_stock}}</td>
                            <td [ngClass]="{ 'text-danger': (comboMetaRawMaterialProduct.current_stock <= comboMetaRawMaterialProduct.moq_level) }">{{comboMetaRawMaterialProduct.moq_level}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.close('Accept click')" rippleEffect>
            Close
        </button>
    </div>
</ng-template>
<!-- / Modal -->