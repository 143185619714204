import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'app/auth/service';
import { RowMaterialProductService } from 'app/_services/row-material-product.service';
import { PurchaseOrderService } from 'app/_services/purchase-order.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
const now = new Date();

@Component({
  selector: 'app-purchase-non-order-add',
  templateUrl: './purchase-non-order-add.component.html',
  styleUrls: ['./purchase-non-order-add.component.scss']
})
export class PurchaseNonOrderAddComponent implements OnInit {

  public purchaseProducts: any[] = [{
    id: 1,
    product_name: '',
    product_price: '0',
    quantity: '1',
    product_total: '0'
  }];

  public loading = false;
  public error = '';
  public newForm: FormGroup;
  public submitted = false;
  public vendorDropDown: any = [];
  public rowMaterialProductDropDown: any = [];
  public rowMaterialCategoryDropDown: any = [];
  public rowMaterialProductsDropDown: any = [];
  public basicDPdata: NgbDateStruct = { year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate() };
  public currentUser: any;
  public currentUserRole = '';
  
  constructor(private _authenticationService: AuthenticationService, private purchaseOrderService: PurchaseOrderService, private rowMaterialProductService: RowMaterialProductService, private _toastrService: ToastrService, private _router: Router, private route: ActivatedRoute, private _formBuilder: FormBuilder) { 
    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));
    this.currentUserRole = JSON.parse(localStorage.getItem('currentUser')).user.role;
  }

  addMore() {
    this.purchaseProducts.push({
      id: this.purchaseProducts.length + 1,
      product_name: '',
      product_price: '0',
      quantity: '1',
      product_total: '0'
    });
  }

  remove(i: number) {
    this.purchaseProducts.splice(i, 1);
  }

  ngOnInit(): void {
    this.getVendorsDropDown();

    this.newForm = this._formBuilder.group({
      vendor_id: ["0", [Validators.required]],
      order_date: [null, [Validators.required]],
    });
  }
  get f() {
    return this.newForm.controls;
  }



  getVendorsDropDown() {
    this.rowMaterialProductService.getVendors().subscribe(
      data => {
        if (data.status) {
          this.vendorDropDown = data.vendors;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

 
  onSubmit() {

    const remarks: any = document.querySelector<HTMLInputElement>('textarea[id="remarks"]').value;

    this.submitted = true;
    // stop here if form is invalid
    if (this.newForm.invalid) {

    }
    else {
      
      let po_date = this.f.order_date.value['year']+'-'+this.f.order_date.value['month']+'-'+this.f.order_date.value['day'];

      this.purchaseOrderService.addNon(this.f.vendor_id.value, po_date, remarks, this.purchaseProducts).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.submitted = false;
            this.newForm.reset();
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            this._router.navigate(['/purchase-order']);
          }
          else {
            this._toastrService.error(data.message, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
    }
  }

  priceChange(event: any, id: any, key: any) {

    if (event.target.value > 0 && this.purchaseProducts[key].quantity > 0) {
      this.purchaseProducts[key].product_total = event.target.value * this.purchaseProducts[key].quantity;
    }
    else {
      this.purchaseProducts[key].product_total = 0;
    }
  }

  qtyChange(event: any, id: any, key: any) {

    if (event.target.value > 0 && this.purchaseProducts[key].product_price > 0) {
      this.purchaseProducts[key].product_total = event.target.value * this.purchaseProducts[key].product_price;
    }
    else {
      this.purchaseProducts[key].product_total = 0;
    }
  }

  getTotal(key) {
    if (this.purchaseProducts[key].quantity > 0 && this.purchaseProducts[key].product_price > 0) {
      this.purchaseProducts[key].product_total = this.purchaseProducts[key].quantity * this.purchaseProducts[key].product_price;
    }
    else {
      this.purchaseProducts[key].product_total = 0;
    }
  }

}
