import { AfterViewInit, Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { RowMaterialProductService } from 'app/_services/row-material-product.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'app/auth/service';
import { Router } from '@angular/router';
import { OrdersService } from 'app/_services/orders.service';
import { WarehouseService } from 'app/_services/warehouse.service';

@Component({
  selector: 'app-stock-transfer-finished-product',
  templateUrl: './stock-transfer-finished-product.component.html',
  styleUrls: ['./stock-transfer-finished-product.component.scss']
})
export class StockTransferFinishedProductComponent implements OnInit {

  @ViewChild('auto') auto: any;

  public loading = false;
  public error = '';
  public newForm: FormGroup;
  public submitted = false;

  public keyword = 'name';
  public keywordProduct = 'full_name';
  public historyHeading: string = 'Recently selected';
  public isLoading: boolean = false;
  public isLoadingProduct: boolean = false;
  public allProduct: any;
  public transfarList: any = [];
  public productQty: number = 0;
  public product: any = [];
  public warehouseDropdownData: any = [];

  constructor(private _router: Router, private warehouseService: WarehouseService, private _authenticationService: AuthenticationService, private ordersService: OrdersService, private _toastrService: ToastrService, private http: HttpClient, private rowMaterialProductService: RowMaterialProductService, private _coreSidebarService: CoreSidebarService, private modalService: NgbModal, private _formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.loading = true;
    this.newForm = this._formBuilder.group({
      from_unit: ['1', Validators.required],
      to_unit: ['0', Validators.required],
      availablestock: ['0', Validators.required],
    });
    this.warehouseDropdown();
  }
  get f() {
    return this.newForm.controls;
  }
  onChangeSearchProduct(value: string) {
    if (value.length >= 3) {
      this.isLoadingProduct = true
      this.ordersService.productSearchAll(value).subscribe(
        data => {
          this.isLoadingProduct = false
          if (data.status) {
            this.allProduct = data.product_data;
          }
          else {
            this._toastrService.clear();
            this._toastrService.error(data.message, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
          this._toastrService.clear();
          this._toastrService.error(err.message, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      );
    }
  }
  selectEventProduct(item: any) {
    console.log(item);
    this.f.availablestock.setValue(item.current_stock);
    this.product = item;
  }

  onFocusedProduct(e: any) {
    // do something when input is focused
  }

  countChange(qty: any) {
    this.productQty = qty;
  }

  selectProduct() {

    if (this.transfarList.length > 0 && this.transfarList.some((detail: { id: any; }) => detail.id === this.product.id)) {
      this._toastrService.clear();
      this._toastrService.warning('Product already added', 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
    } else {
      if (this.product.id !== undefined) {
        if (this.productQty > 0) {
          this.product.productQty = this.productQty;
          this.transfarList.push(this.product);
          this.transfarList.reverse();
        }
        else {
          this._toastrService.clear();
          this._toastrService.warning('Please enter transfer Stock', 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      }
      else {
        this._toastrService.clear();
        this._toastrService.warning('Please select any product', 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    }
    this.product = [];
    this.productQty = 0;
    this.f.availablestock.setValue('0');
    this.clear();
    this.auto.close();
  }

  clear(): void {
    this.auto.clear();
  }

  warehouseDropdown() {
    this.warehouseService.warehouseDropdown().subscribe(
      data => {
        if (data.status) {
          this.warehouseDropdownData = data.warehouses;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

}
