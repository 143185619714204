import { AfterViewInit, Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { OrdersService } from 'app/_services/orders.service';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'app/auth/service';
import { ProductsService } from 'app/_services/products.service';


@Component({
  selector: 'app-orders-add',
  templateUrl: './orders-add.component.html',
  styleUrls: ['./orders-add.component.scss']
})
export class OrdersAddComponent implements OnInit {

  @ViewChild('auto') auto: any;

  public keyword = 'name';
  public keywordProduct = 'full_name';
  public historyHeading: string = 'Recently selected';
  public isLoading: boolean = false;
  public isLoadingProduct: boolean = false;
  public allDealer: any;
  public dealerId: any = 0;
  public allProduct: any;
  public order_details: any = [];
  public total_qty: any = 0;
  public newForm: FormGroup;
  public loading: boolean = false;
  public dealerSelected: boolean = true; 
  public dealerSelectedCat: boolean = false; 

  public categoryDropDown: any;

  constructor(private _authenticationService: AuthenticationService,private productsService: ProductsService, private http: HttpClient, private ordersService: OrdersService, private _toastrService: ToastrService, private _router: Router, private route: ActivatedRoute, private _formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.newForm = this._formBuilder.group({
      poNumber: [''],
      remarks: [''],
    });
    this.getCategoryDropDown();

  }

  get f() {
    return this.newForm.controls;
  }

  onChangeSearch(value: string) {
    this.isLoading = true
    this.dealerSelected = true;
    this.dealerSelectedCat = false;
    this.ordersService.dealerSearch(value).subscribe(
      data => {
        if (data.status) {
          this.isLoading = false
          this.allDealer = data.dealer_data;
          this.allProduct = [];
          this.order_details = [];
        }
        else {
          this._toastrService.clear();
          this._toastrService.error(data.message, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
        this._toastrService.clear();
        this._toastrService.error(err.message, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );
  }

  selectEvent(item: any) {
    this.dealerId = item.id;
    this.dealerSelected = false;
    this.dealerSelectedCat = true;
    

  }

  onFocused(e: any) {
    // do something when input is focused
  }

  clear(): void {
    this.auto.clear();
  }

  onChangeSearchProduct(value: string) {

    if (value.length >= 3) {
      this.isLoadingProduct = true
      this.ordersService.productSearch(this.dealerId, value).subscribe(
        data => {
          this.isLoadingProduct = false
          if (data.status) {
            for (var i = 0; i < data.product_data.length; i++) {
              if (data.product_data[i].discount > 0) {
                data.product_data[i].discount = data.product_data[i].sales_price - ((data.product_data[i].sales_price * data.product_data[i].discount) / 100)
              }
            }

            this.allProduct = data.product_data;
          }
          else {
            this._toastrService.clear();
            this._toastrService.error(data.message, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
          this._toastrService.clear();
          this._toastrService.error(err.message, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      );
    }

  }
  selectEventProduct(item: any) {
    if (this.order_details.length > 0 && this.order_details.some((detail: { id: any; }) => detail.id === item.id)) {
      this._toastrService.clear();
      this._toastrService.warning('Product already added', 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
    } else {
      item.qty = item.min_order_qty;
      item.total = item.sales_price;
      this.total_qty += item.min_order_qty;
      this.order_details.push(item);
      this.order_details.reverse();
    }
    this.clear();
  }

  onFocusedProduct(e: any) {
    // do something when input is focused
  }
  countChange(qty: any, product_id: any) {
    this.total_qty = 0;
    for (var i = 0; i < this.order_details.length; i++) {
      if (this.order_details[i].id === product_id) {
        this.order_details[i].qty = parseFloat(qty);
        this.order_details[i].total = this.order_details[i].sales_price * parseFloat(qty);
      }
      this.total_qty += this.order_details[i].qty;
    }
  }
  productDelete(product_id: any) {
    for (var i = 0; i < this.order_details.length; i++) {
      if (this.order_details[i].id == product_id) {
        this.total_qty -= this.order_details[i].qty;
        this.order_details.splice(i, 1);
      }
    }
  }
  storeDealerOrder() {

    this.loading = true;

    this.ordersService.storeOrder(this.dealerId, this.order_details, this.f.remarks.value,this.f.poNumber.value).subscribe(
      data => {
        this.loading = false;
        if (data.status) {
          this._toastrService.clear();
          this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
          this.order_details = [];
          this._router.navigate(['/orders']);

        }
        else {
          this._toastrService.clear();
          this._toastrService.error(data.errorMessage, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
        }
      },
      err => {
        this.loading = false;
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
        this._toastrService.clear();
        this._toastrService.error(err.errorMessage, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );

  }

  removeAll() {
    this.order_details = [];
    this.total_qty = 0;
  }

  closedStatic() {
    this.allProduct = [];
    this.order_details = [];
    this.dealerId = 0;
    this.total_qty = 0;
    this.dealerSelected = true;
    this.dealerSelectedCat = false;
  }
  getCategoryDropDown() {
    this.productsService.getCategoriesDropDown().subscribe(
      data => {
        if (data.status) {
          this.categoryDropDown = data.categories;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }
}

