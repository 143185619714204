export const locale = {
  lang: 'en',
  data: {
    MENU: {
      HOME: 'Dashboard',
      MASTER: 'Master',
      CATEGORIES: 'Categories',
      DEALERS: 'Customers',
      PRODUCTS: 'Products',
      ORDERS: 'Orders',
      DELIVERYCHALLAN: 'Delivery Challan',
      PRICEPERCUSTOMER: 'Price Per Customers',
      REPORTS: 'Reports',
      ORDER_REPORTS: 'Order Reports',
      CHALLAN_REPORTS: 'Challan Reports',
      MANUFACTURERS: 'Supplier',
      EMPLOYEES: 'Sales Executive',
      HELP: 'Help',
      CUSTOMER_GUIDE: 'Customer Guide',
      SUPPLIER_GUIDE: 'Supplier Guide',
      UNIT: 'Units',
      OUTSTANDING_REPORT: 'Outstanding Report',
      PRODUCT_COMBO_REPORT: 'Product Combo Report',
      TOP_CUSTOMERS_REPORT: 'Top Customers Report',
      DEPARTMENTS: 'Departments',
      VENDOR: 'Vendors',
      PURCHASE_ORDER: 'Purchase Order',
      ROWMATERIALCATEGORY: 'Row Material Category',
      ROWMATERIALPRODUCT: 'Row Material Product',
      VENDORCATEGORIES: 'Vendor Category',
      VENDORSUBCATEGORIES: 'Vendor Sub Category',
      VENDORMASTER: 'Vendor Master',
      ROWMATERIALSUBCATEGORY: 'Row Material Sub Category',
      ROWMATERIALMASTER: 'Row Material Master',
      PRODUCTCOMBO: 'Product Combo',
      TRANSPORT: 'Transport',
      OPERATORS: 'Operators',
      MACHINE: 'Machines',
      PRODUCTIONSUBMIT: 'Planning Submit',
      WAREHOUSE: 'Warehouse',
      PRODUCTIONGIVEN: 'Planning Given',
      STOREDEPARTMENT: 'Store Department',
      PRODUCTIONDEPARTMENT: 'Production Department',
      PURCHASEDEPARTMENT: 'Purchase Department',
      PRODUCTWISERAWMATERIALSTOCK: 'Product Wise Raw-Material Stock',
      STOCKTRANSFER: 'Stock Transfer',
      RAWMATERIALSHARING: 'Raw Material Sharing',
      STOCKGIVEN: 'Stock Given',
      STOCKRECEIVE: 'Stock Receive',
      PURCHASEREQUISITION: 'Purchase Requisition',
      TODO: 'To Do',
      STOCK: 'Stock',
      PRODUCTION: 'Planning',
      GATEPASS: 'Gate Pass',
    }
  }
}
