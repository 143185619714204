import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
const headers = new HttpHeaders({ 'Content-Type': 'application/json', responseType : 'blob'});


@Injectable({
  providedIn: 'root'
})
export class PurchaseOrderService {

  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  getData(formdata): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-purchase-order-add`, formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  add(vendor_id: any, order_date: any, remarks: any, purchaseProducts: any): Observable<any> {

    return this._http
      .post(`${environment.apiUrl}save-purchase-order-add`, { vendor_id, order_date, remarks, purchaseProducts })
      .pipe(
        map(data => {
          return data;
        })
      );

  }

  getRowMaterialProductsDropDown(id:any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-row-material-product-dropdown`,{id})
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  getDetails(id:any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-purchase-order-details`,{id})
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  saveReceive(purchaseOrderReceive:any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}save-purchase-order-receive`,purchaseOrderReceive)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  orderchangeStatus(id: string, status: string): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}change-purchase-order-status`, { status,id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  getAllRowMaterialProductsDropDown(): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}get-all-row-material-dropdown`)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  delete(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}purchase-order-delete`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  downloadPdf(id:string): Observable<Blob>{
    return this._http.post<Blob>(`${environment.apiUrl}purchase-order-download`,{id},{ headers : headers,responseType : 
      'blob' as 'json'});
  }

  getVendor(): Observable<any> {
    const userDetail = JSON.parse(localStorage.getItem("currentUser"));
    return this._http
      .get(`${environment.apiUrl}get-vendors-dropdown`)
      .pipe(
        map(data => {
          return data;
        })
      );
  }


  getDataNon(formdata): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-non-purchase-order-add`, formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  deleteNon(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}purchase-non-order-delete`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  orderChangeStatusNon(id: string, status: string): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}change-non-purchase-order-status`, { status,id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  downloadPdfNon(id:string): Observable<Blob>{
    return this._http.post<Blob>(`${environment.apiUrl}purchase-non-order-download`,{id},{ headers : headers,responseType : 
      'blob' as 'json'});
  }

  getDetailsNon(id:any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-non-purchase-order-details`,{id})
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  saveReceiveNon(purchaseOrderReceive:any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}save-non-purchase-order-receive`,purchaseOrderReceive)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  addNon(vendor_id: any, order_date: any, remarks: any, purchaseProducts: any): Observable<any> {

    return this._http
      .post(`${environment.apiUrl}save-non-purchase-order-add`, { vendor_id, order_date, remarks, purchaseProducts })
      .pipe(
        map(data => {
          return data;
        })
      );

  }

}
