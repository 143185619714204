import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class ProductMasterComboService {

  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  getData(start, length): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-product-combo-auto`, { start, length })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  add(products_id: any, combo_name: any, comboProducts: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}save-product-combo`, { products_id, combo_name, comboProducts })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  viewDetails(comboId): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-product-combo-auto-details`, { comboId })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  delete(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-product-combo-delete`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

}
