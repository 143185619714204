<!-- <div id="mySidenav" class="sidenav">
    <button type="button" (click)="copyText()" id="link" class="btn btn-success text-center ml-2" data-toggle="tooltip" data-popup="tooltip-custom" placement="left" ngbTooltip="You can share this link to take orders from anyone customer."><i class="fa fa-copy"></i> Order Master Link</button>
</div> -->
<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="row" *ngIf="planExpired">
            <!-- ngb-alert component -->
            <ngb-alert [type]="'warning'" [dismissible]="false" class="col-12">
                <h4 class="alert-heading">
                    <marquee> Your plan is expiring in {{ planExpiredDay }} days...</marquee>
                </h4>
            </ngb-alert>
            <!--/ ngb-alert component -->
        </div>
        <!-- Stats Vertical Card -->
        <div class="row match-height">
            <div class="col-6 col-xl-3 col-md-3 col-sm-6 col-xs-6">
                <div class="card text-center">
                    <a routerLink="/orders">
                        <div class="card-body">
                            <div class="media">
                                <div class="avatar bg-light-primary mr-2">
                                    <div class="avatar-content">
                                        <i data-feather="shopping-cart" class="avatar-icon"></i>
                                    </div>
                                </div>
                                <div class="media-body my-auto">
                                    <h4 class="font-weight-bolder mb-0">{{ todayOrder }}</h4>
                                    <p class="card-text font-small-3 mb-0">Today Order</p>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-6 col-xl-3 col-md-3 col-sm-6 col-xs-6">
                <div class="card text-center">
                    <a routerLink="/orders">
                        <div class="card-body">
                            <div class="media">
                                <div class="avatar bg-light-info mr-2">
                                    <div class="avatar-content">
                                        <i data-feather="trending-up" class="avatar-icon"></i>
                                    </div>
                                </div>
                                <div class="media-body my-auto">
                                    <h4 class="font-weight-bolder mb-0">{{ allOrder }}</h4>
                                    <p class="card-text font-small-3 mb-0">Total Order</p>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-6 col-xl-3 col-md-3 col-sm-6 col-xs-6">
                <div class="card text-center">
                    <a routerLink="/products">
                        <div class="card-body">
                            <div class="media">
                                <div class="avatar bg-light-danger mr-2">
                                    <div class="avatar-content">
                                        <i data-feather="box" class="avatar-icon"></i>
                                    </div>
                                </div>
                                <div class="media-body my-auto">
                                    <h4 class="font-weight-bolder mb-0">{{ allProduct }}</h4>
                                    <p class="card-text font-small-3 mb-0">Total Products</p>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-6 col-xl-3 col-md-3 col-sm-6 col-xs-6">
                <div class="card text-center">
                    <a routerLink="/dealers">
                        <div class="card-body">
                            <div class="media">
                                <div class="avatar bg-light-primary mr-2">
                                    <div class="avatar-content">
                                        <i data-feather="user" class="avatar-icon"></i>
                                    </div>
                                </div>
                                <div class="media-body my-auto">
                                    <h4 class="font-weight-bolder mb-0">{{ allDealer }}</h4>
                                    <p class="card-text font-small-3 mb-0">Total Customers</p>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <!--/ Stats Vertical Card -->

        <div class="row match-height" *ngIf="currentUserRole == 'Client'">
            <!-- Last Two Days Orders card -->
            <div class="col-lg-4 col-md-6 col-12">
                <div class="card card-transaction">
                    <div class="card-header">
                        <h4 class="card-title">Latest Orders</h4>
                    </div>
                    <div class="card-body">
                        <div class="col  p-3" *ngIf="loading">
                            <div class="text-center">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div>
                                    Please Wait...
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!loading">
                            <div class="overlay" *ngIf="progressLatestOrder">
                                <div class="spinner-grow" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                            <div class="transaction-item" *ngFor="let order of lastTowDayOrder; let ndx = index">
                                <div class="media" (click)="modalOpen(modalBasic,order.id)">
                                    <div class="avatar bg-light-warning rounded" *ngIf="order.status == 0">
                                        <div class="avatar-content">
                                            <i data-feather="info" class="avatar-icon font-medium-3"></i>
                                        </div>
                                    </div>
                                    <div class="avatar bg-light-primary rounded" *ngIf="order.status == 1">
                                        <div class="avatar-content">
                                            <i data-feather="clock" class="avatar-icon font-medium-3"></i>
                                        </div>
                                    </div>
                                    <div class="avatar bg-light-primary rounded" *ngIf="order.status == 2">
                                        <div class="avatar-content">
                                            <i data-feather="clock" class="avatar-icon font-medium-3"></i>
                                        </div>
                                    </div>
                                    <div class="avatar bg-light-success rounded" *ngIf="order.status == 3">
                                        <div class="avatar-content">
                                            <i data-feather="check" class="avatar-icon font-medium-3"></i>
                                        </div>
                                    </div>
                                    <div class="avatar bg-light-danger rounded" *ngIf="order.status == 4">
                                        <div class="avatar-content">
                                            <i data-feather="trash" class="avatar-icon font-medium-3"></i>
                                        </div>
                                    </div>
                                    <div class="media-body">
                                        <h6 class="transaction-title">Order No #{{ order.invoice_no }}</h6>
                                        <small>{{ order.customer_name }}</small>
                                    </div>
                                </div>
                                <div class="font-weight-bolder text-muted">{{ order.order_date }}</div>
                            </div>
                            <div *ngIf="lastTowDayOrder?.length == 0">


                                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                                    <img class="img-fluid opacity-50" [src]="'assets/images/pages/no-data.svg'" alt="No Data Found!" />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="cart-footer text-right">

                        <ngb-pagination class="mr-1 pull-right" [collectionSize]="collectionSizeOrder" *ngIf="collectionSizeOrder > 5" [(page)]="pageAdvancedEllipsesOrder" [maxSize]="3" (pageChange)="loadPageOrder($event)" [pageSize]="lengths" [rotate]="true">

                        </ngb-pagination>
                    </div>
                </div>
            </div>
            <!--/ Last Two Days Orders card -->
            <!-- Order Chart card -->
            <div class="col-lg-4 col-md-6 col-12">
                <div class="card card-transaction">
                    <div class="card-header">
                        <h4 class="card-title">Orders & Dispatched Chart</h4>
                    </div>
                    <div class="card-body text-center">
                        <div id="chartjs-bar-chart" *ngIf="!loadingBar">
                            <canvas baseChart #barChartRef height="300" [datasets]="barChart.datasets" [labels]="barChart.labels" [options]="barChart.options" [legend]="barChart.legend" [chartType]="barChart.chartType"></canvas>
                        </div>

                        <div class="col  p-3" *ngIf="loading">
                            <div class="text-center">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div>
                                    Please Wait...
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <!--/ Pending Order with Due Day card -->
            <!-- Pending Order with Due Day card -->
            <div class="col-lg-4 col-md-6 col-12">
                <div class="card card-transaction">
                    <div class="card-header">
                        <h4 class="card-title">Pending Order with Due Day</h4>
                    </div>
                    <div class="card-body">
                        <div class="col  p-3" *ngIf="loadingDueDay">
                            <div class="text-center">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div>
                                    Please Wait...
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!loadingDueDay">
                            <div class="overlay" *ngIf="progressPendingOrder">
                                <div class="spinner-grow" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                            <div class="transaction-item" *ngFor="let pendingOrder of pendingOrderWithDueDay; let ndx = index">
                                <div class="media" (click)="modalOpen(modalBasic,pendingOrder.id)">

                                    <div class="avatar bg-light-danger rounded">
                                        <div class="avatar-content">
                                            {{ pendingOrder.due_days }} <br/> Days
                                        </div>
                                    </div>
                                    <div class="media-body">
                                        <h6 class="transaction-title">Order No #{{ pendingOrder.invoice_no }}</h6>
                                        <small>{{ pendingOrder.customer_name }}</small>
                                    </div>
                                </div>
                                <div class="font-weight-bolder text-muted">{{ pendingOrder.order_date }}</div>
                            </div>

                            <div *ngIf="pendingOrderWithDueDay?.length == 0">

                                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                                    <img class="img-fluid opacity-50" [src]="'assets/images/pages/no-data.svg'" alt="No Data Found!" />
                                </div>

                            </div>
                        </div>

                    </div>
                    <div class="cart-footer text-right">
                        <ngb-pagination class="mr-1 pull-right" [collectionSize]="collectionSize" *ngIf="collectionSize > 5" [(page)]="pageAdvancedEllipses" [maxSize]="3" (pageChange)="loadPage($event)" [pageSize]="lengths" [rotate]="true">

                        </ngb-pagination>
                    </div>
                </div>
            </div>
            <!--/ Pending Order with Due Day card -->
            <!-- Stock Alerts card -->
            <div class="col-lg-4 col-md-6 col-12" *ngIf="is_stock_handle == 1">
                <div class="card card-transaction">
                    <div class="card-header">
                        <h4 class="card-title">Stock Alerts</h4>
                    </div>
                    <div class="card-body">
                        <div class="col  p-3" *ngIf="loadingStockAlert">
                            <div class="text-center">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div>
                                    Please Wait...
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!loadingStockAlert">
                            <div class="overlay" *ngIf="progressStockAlerts">
                                <div class="spinner-grow" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                            <div class="employee-task d-flex justify-content-between align-items-center pb-2" *ngFor="let product of stockAlert; let ndx = index">
                                <div class="media">
                                    <div class="avatar mr-75">
                                        <img src="assets/images/no-img.png" *ngIf="product.product_photo == null" class="rounded " width="42 " height="42 " alt="P" />
                                        <img src="{{ product.product_photo }}" *ngIf="product.product_photo != null" class="rounded " width="42 " height="42 " alt="P" />

                                    </div>
                                    <div class="media-body">
                                        <h6 class="mb-0">{{ product.product_code }} {{ product.product_name }}</h6>
                                        <small>{{ product.size_title }} {{ product.color_title }}</small>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center ">
                                    <span class="font-weight-bolder text-danger"> Qty : {{ product.current_stock }}</span>
                                </div>
                            </div>
                            <div *ngIf="stockAlert?.length == 0">

                                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                                    <img class="img-fluid opacity-50" [src]="'assets/images/pages/no-data.svg'" alt="No Data Found!" />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="cart-footer text-right">

                        <ngb-pagination class="mr-1 pull-right" [collectionSize]="collectionSizeStockAlert" *ngIf="collectionSizeStockAlert > 5" [(page)]="pageAdvancedEllipsesStockAlert" [maxSize]="3" (pageChange)="loadStockAlertPage($event)" [pageSize]="lengthsStockAlert" [rotate]="true">

                        </ngb-pagination>
                    </div>
                </div>
            </div>
            <!--/ Stock Alerts card -->
            <!-- Fast Moving Products Card -->
            <div class="col-lg-4 col-md-6 col-12">
                <div class="card card-employee-task">
                    <div class="card-header">
                        <h4 class="card-title">Fast Moving Products</h4>
                    </div>
                    <div class="card-body">
                        <div class="col  p-3" *ngIf="loading">
                            <div class="text-center">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div>
                                    Please Wait...
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!loading">
                            <div class="employee-task d-flex justify-content-between align-items-center" *ngFor="let fastproduct of fastMoving; let ndx = index">
                                <div class="media">
                                    <div class="avatar mr-75">
                                        <img src="assets/images/no-img.png" *ngIf="fastproduct.product_photo == null" class="rounded " width="42 " height="42 " alt="P" />
                                        <img src="{{ fastproduct.product_photo }}" *ngIf="fastproduct.product_photo != null" class="rounded " width="42 " height="42 " alt="P" />

                                    </div>
                                    <div class="media-body my-auto ">
                                        <h6 class="mb-0 ">{{ fastproduct.product_code }} {{ fastproduct.product_name }}</h6>
                                        <small>{{ fastproduct.size_title }} {{ fastproduct.color_title }}</small>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center ">
                                    <span class="font-weight-bolder  text-success">Sold Qty : {{ fastproduct.total_qty }}</span>
                                </div>
                            </div>
                            <div *ngIf="fastMoving?.length == 0">

                                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                                    <img class="img-fluid opacity-50" [src]="'assets/images/pages/no-data.svg'" alt="No Data Found!" />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--/ Fast Moving Products Card -->
            <!-- Slow Moving Products Card -->
            <div class="col-lg-4 col-md-6 col-12">
                <div class="card card-employee-task">
                    <div class="card-header">
                        <h4 class="card-title">Slow Moving Products</h4>
                    </div>
                    <div class="card-body">
                        <div class="col p-3" *ngIf="loading">
                            <div class="text-center">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div>
                                    Please Wait...
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!loading">
                            <div class="employee-task d-flex justify-content-between align-items-center" *ngFor="let slowproduct of slowMoving; let ndx = index">
                                <div class="media">
                                    <div class="avatar mr-75">
                                        <img src="assets/images/no-img.png" *ngIf="slowproduct.product_photo == null" class="rounded " width="42 " height="42 " alt="P" />
                                        <img src="{{ slowproduct.product_photo }}" *ngIf="slowproduct.product_photo != null" class="rounded " width="42 " height="42 " alt="P" />

                                    </div>
                                    <div class="media-body my-auto ">
                                        <h6 class="mb-0 ">{{ slowproduct.product_code }} {{ slowproduct.product_name }}</h6>
                                        <small>{{ slowproduct.size_title }} {{ slowproduct.color_title }}</small>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center ">
                                    <span class="font-weight-bolder text-danger">Sold Qty : {{ slowproduct.total_qty }}</span>
                                </div>
                            </div>
                            <div *ngIf="slowMoving?.length == 0">

                                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                                    <img class="img-fluid opacity-50" [src]="'assets/images/pages/no-data.svg'" alt="No Data Found!" />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--/ Slow Moving Products Card -->
        </div>
    </div>
</div>

<!-- Modal -->
<ng-template #modalBasic let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1" *ngIf="orderDetails?.length != 0">#{{ orderDetails.invoice_no }} </h4>
        <h4 class="modal-title" id="myModalLabel1" *ngIf="orderDetails?.length == 0">Order Details</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col" *ngIf="orderDetails?.length == 0">
            <div class="text-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div>
                    Please Wait...
                </div>
            </div>
        </div>
        <div class="row" *ngIf="orderDetails?.length != 0" id="print-section">
            <div class="col-12 pt-1 overflow-auto">
                <table class="table" style="width:100%;">
                    <tbody>
                        <tr>
                            <td style="padding:5px;" *ngIf="currentUser.user.profile_image == null">
                                <img src="assets/images/logo/logo-text.png" style="width: 150px; height: 50px;" />
                            </td>
                            <td style="padding:5px;" *ngIf="currentUser.user.profile_image != null">
                                <img [src]="currentUser.user.profile_image" width="100" height="auto" />
                            </td>
                            <td align="right" style="padding:5px;">
                                <h3 style="margin: 2px;">{{ currentUser.user.name }}</h3>
                                <h5 style="margin: 2px;">Mobile : {{ currentUser.user.mobile }}</h5>
                                <h5 style="margin: 2px;">Email : {{ currentUser.user.email }}</h5>
                                <h5 style="margin: 2px;" *ngIf="currentUser.user.is_gst_print == 1">GSTIN : {{ currentUser.user.gst_no | uppercase }}</h5>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table class="table" style="width:100%;margin-top: 15px;">
                    <tbody>
                        <tr>
                            <td style="padding:5px;">
                                <b>Name : </b> {{ orderDetails.name }}<br/>
                                <b>Phone : </b> {{ orderDetails.phone }} <span *ngIf="orderDetails.landline != '' && orderDetails.landline != null"> / {{  orderDetails.landline }}</span><br/>
                                <b>Address : </b> {{ orderDetails.address_one }}, {{ orderDetails.address_two }}, {{ orderDetails.address_three }}<br/>
                                <b>City : </b> {{ orderDetails.city_name }} - {{ orderDetails.state_name}}<br/>
                                <b *ngIf="orderDetails.gst_no != '' && orderDetails.gst_no != null">GSTIN : </b> {{ orderDetails.gst_no }}
                            </td>
                            <td align="right" style="padding:5px;">
                                <b>Order No : </b> #{{ orderDetails.invoice_no }} <br/>
                                <b *ngIf="orderDetails.po_number != null && orderDetails.po_number != ''">P.O. Number : </b> {{ orderDetails.po_number }} <br/>
                                <b>Order Date : </b> {{ orderDetails.order_date }}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table class="table table-hover table-striped" style="width:100%;margin-top:15px;">
                    <thead>
                        <tr style="font-weight:bold;">
                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">Sr.No.</td>
                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">Product</td>
                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;" *ngIf="currentUser.user.is_show_price == 1">Rate</td>
                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">Qty</td>
                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">Dispatch</td>
                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">Remaining</td>
                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;" *ngIf="currentUser.user.is_show_price == 1">Sub Total</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let orderProduct of orderDetails.order_details; let sr = index">
                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">{{sr+1}}</td>
                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">
                                {{ orderProduct.product_code }} {{ orderProduct.product_name }}
                                <small *ngIf="orderProduct.size_title != '' && orderProduct.color_title != ''"><br/>{{ orderProduct.size_title }} {{ orderProduct.color_title }}</small>
                            </td>
                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;" *ngIf="currentUser.user.is_show_price == 1 && orderProduct.discount == 0">₹ {{orderProduct.sales_price | number:'1.2-2'}}</td>
                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;" *ngIf="currentUser.user.is_show_price == 1 && orderProduct.discount > 0"><small><del class="text-danger">₹ {{orderProduct.sales_price | number:'1.2-2'}}</del></small><br/>₹ {{ orderProduct.discount_price | number:'1.2-2'}}</td>
                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">{{orderProduct.order_qty}}</td>
                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">{{orderProduct.dispatch_qty}}</td>
                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">{{orderProduct.remaining_qty}}</td>
                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;" *ngIf="currentUser.user.is_show_price == 1">₹ {{orderProduct.sub_total | number:'1.2-2'}}</td>
                        </tr>
                        <tr style="font-weight:bold;">
                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;" colspan="3" *ngIf="currentUser.user.is_show_price == 1" align="right" class="text-right">Total : &nbsp;&nbsp; </td>
                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;" colspan="2" *ngIf="currentUser.user.is_show_price != 1" align="right" class="text-right">Total : &nbsp;&nbsp; </td>
                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">{{ getSum('order_qty') }}</td>
                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">{{ getSum('dispatch_qty') }}</td>
                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">{{ getSum('remaining_qty') }}</td>
                            <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;" *ngIf="currentUser.user.is_show_price == 1">₹ {{ getSum('sub_total') | number:'1.2-2'}}</td>
                        </tr>
                    </tbody>
                </table>
                <table class="table" style="width:100%;">
                    <tbody>
                        <tr *ngIf="orderDetails.remarks != ''">
                            <td><b>Remarks : </b> {{ orderDetails.remarks }}</td>
                        </tr>
                        <tr>
                            <td style="padding:5px;background-color: #1e9dd6;text-align: center;color:rgb(194, 194, 194)">
                                Powered By : Pro Clean
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.close('Accept click')" rippleEffect>
            Close
        </button>
        <button type="button" class="btn btn-success" printSectionId="print-section" ngxPrint><i class="fa fa-print"></i> Print</button>
    </div>
</ng-template>
<!-- / Modal -->