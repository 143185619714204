<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <!-- Start for PR List   -->
            <div class="row" *ngIf="polist">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Purchase Requisition List</h4>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs px-1">
                                <li ngbNavItem [ngbNavItem]="1">
                                    <a ngbNavLink  (click)="setActive(1)">Product</a>
                                    <ng-template ngbNavContent>
                                        <div class="row m-0" *ngIf="polist">
                                            <div class="col-md-6 col-4">
                                                <div class="d-flex justify-content-between align-items-center ml-1">
                                                    <label class="d-flex align-items-center">Show
                                                    <select class="form-control mx-25" [(ngModel)]="lengths" (change)="loadPageLenghs($event)" >
                                                        <option value="30">30</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                    </select>
                                                  </label>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-8 d-flex justify-content-start justify-content-md-end pb-1">
                                                <div class="d-flex w-100 align-items-end justify-content-end pr-1 pb-1 pb-md-0">
                                                    <button class="btn btn-primary ml-1" *ngIf="currentUserRole == 'Purchase'" rippleEffect (click)="makePO()" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Make PO">
                                                        <i data-feather="shopping-cart" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                                        <span class="d-none d-sm-inline-block">Make PO</span>
                                                    </button>
                                                    <button class="btn btn-success ml-1" *ngIf="currentUserRole != 'Client'" rippleEffect (click)="toggleSidebar('new-sidebar')" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Add New">
                                                        <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                                        <span class="d-none d-sm-inline-block">Add New</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col p-0  table-responsive">
                                            <div class="overlay" *ngIf="progressMainTable">
                                                <div class="spinner-grow" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                            <table class="table table-striped table-hover valign-middle text-dark pb-5">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th *ngIf="currentUserRole == 'Client' || currentUserRole == 'Purchase'"> Department</th>
                                                        <th> Raw Material Product</th>
                                                        <th> Quantity</th>
                                                        <th> Priority</th>
                                                        <th> Request Date</th>
                                                        <th *ngIf="currentUserRole != 'Client'"> Expected Arrival</th>
                                                        <th> Status</th>
                                                        <th> Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody *ngIf="requisitionList?.length != 0">
                                                    <tr *ngFor="let requisition of requisitionList; let ndx = index">
                                                        <td>{{ndx+page+1}}</td>
                                                        <td *ngIf="currentUserRole == 'Client' || currentUserRole == 'Purchase'">{{ requisition.userName }}</td>
                                                        <td>{{ requisition.product_name }}</td>
                                                        <td>{{ requisition.qty }}</td>
                                                        <td>{{ requisition.priority }} Days</td>
                                                        <td>{{ requisition.created }}</td>
                                                        <td *ngIf="currentUserRole != 'Client'">{{ requisition.expected }}</td>
                                                        <td *ngIf="currentUserRole == 'Client'">
                                                            <div ngbDropdown container="body" *ngIf="requisition.status == 0">
                                                                <button ngbDropdownToggle class="btn btn-gradient-warning btn-sm" type="button" id="dropdownMenuButton101">
                                                                    Pending
                                                                </button>
                                                                <div ngbDropdownMenu aria-labelledby="dropdownMenuButton101">
                                                                    <a ngbDropdownItem (click)="changeStatus(requisition.id,'1')">Approve</a>
                                                                    <a ngbDropdownItem (click)="changeStatus(requisition.id,'2')">Reject</a>
                                                                </div>
                                                            </div>
                                                            <div ngbDropdown container="body" *ngIf="requisition.status == 1">
                                                                <button ngbDropdownToggle class="btn btn-gradient-success btn-sm" type="button" id="dropdownMenuButton101">
                                                                    Approve
                                                                </button>
                                                                <div ngbDropdownMenu aria-labelledby="dropdownMenuButton101">
                                                                    <a ngbDropdownItem (click)="changeStatus(requisition.id,'0')">Pending</a>
                                                                    <a ngbDropdownItem (click)="changeStatus(requisition.id,'2')">Reject</a>
                                                                </div>
                                                            </div>
                                                            <div ngbDropdown container="body" *ngIf="requisition.status == 2">
                                                                <button ngbDropdownToggle class="btn btn-gradient-danger btn-sm" type="button" id="dropdownMenuButton101">
                                                                    Reject
                                                                </button>
                                                                <div ngbDropdownMenu aria-labelledby="dropdownMenuButton101">
                                                                    <a ngbDropdownItem (click)="changeStatus(requisition.id,'1')">Approve</a>
                                                                    <a ngbDropdownItem (click)="changeStatus(requisition.id,'0')">Pending</a>
                                                                </div>
                                                            </div>
                                                            <span class="badge badge-pill badge-light-primary mr-1" *ngIf="requisition.status == 3">Completed</span>
                                                            <span class="badge badge-pill badge-light-info mr-1" *ngIf="requisition.status == 4">Processing</span>
                                                            <span class="badge badge-pill badge-light-secondary mr-1" *ngIf="requisition.status == 5">Received</span>
                                                        </td>
                                                        <td *ngIf="currentUserRole != 'Client'">
                                                            <span class="badge badge-pill badge-light-warning mr-1" *ngIf="requisition.status == 0">Pending</span>
                                                            <span class="badge badge-pill badge-light-success mr-1" *ngIf="requisition.status == 1">Approved</span>
                                                            <span class="badge badge-pill badge-light-danger mr-1" *ngIf="requisition.status == 2">Rejected</span>
                                                            <span class="badge badge-pill badge-light-primary mr-1" *ngIf="requisition.status == 3">Completed</span>
                                                            <span class="badge badge-pill badge-light-info mr-1" *ngIf="requisition.status == 4">Processing</span>
                                                            <span class="badge badge-pill badge-light-secondary mr-1" *ngIf="requisition.status == 5">Received</span>
                                                        </td>
                                                        <td>
                                                            <div ngbDropdown container="body" *ngIf="requisition.status == 0">
                                                                <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                                                                  <i data-feather="more-vertical"></i>
                                                                </button>
                                                                <div ngbDropdownMenu>
                                                                    <a ngbDropdownItem (click)="toggleSidebar('new-sidebar',requisition.id,ndx)"><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a>
                                                                    <a ngbDropdownItem (click)="delete(requisition.id)"><i data-feather="trash" class="mr-50"></i><span>Delete</span></a>
                                                                </div>
                                                            </div>
                                                            <div class="p-1" *ngIf="requisition.status != 0">
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody *ngIf="requisitionList?.length == 0">
                                                    <tr *ngIf="!loading">
                                                        <td colspan="9" class="no-data-available">No Data!</td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="9" class="no-data-available">
                                                            <div class="col" *ngIf="loading">
                                                                <div class="text-center">
                                                                    <div class="spinner-border" role="status">
                                                                        <span class="sr-only">Loading...</span>
                                                                    </div>
                                                                    <div>
                                                                        Please Wait...
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <ngb-pagination class="mr-1 pull-right" [collectionSize]="collectionSize" *ngIf="collectionSize > 5" [(page)]="pageAdvancedEllipses" [maxSize]="3" (pageChange)="loadPage($event)" [pageSize]="lengths" [rotate]="true"></ngb-pagination>
                                        </div>
                                    </ng-template>
                                </li>
                                <li ngbNavItem [ngbNavItem]="2">
                                    <a ngbNavLink (click)="setActive(2)">Non Product</a>
                                    <ng-template ngbNavContent>
                                        <div class="row m-0" *ngIf="polist">
                                            <div class="col-md-6 col-4">
                                                <div class="d-flex justify-content-between align-items-center ml-1">
                                                    <label class="d-flex align-items-center">Show
                                                    <select class="form-control mx-25" [(ngModel)]="lengthsNon" (change)="loadPageLenghsNon($event)" >
                                                        <option value="30">30</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                    </select>
                                                  </label>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-8 d-flex justify-content-start justify-content-md-end pb-1">
                                                <div class="d-flex w-100 align-items-end justify-content-end pr-1 pb-1 pb-md-0">
                                                    <button class="btn btn-primary ml-1" *ngIf="currentUserRole == 'Purchase'" rippleEffect (click)="makePONon()" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Make PO">
                                                        <i data-feather="shopping-cart" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                                        <span class="d-none d-sm-inline-block">Make PO</span>
                                                    </button>
                                                    <button class="btn btn-success ml-1" *ngIf="currentUserRole != 'Client'" rippleEffect (click)="toggleSidebarNon('new-sidebar')" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Add New">
                                                        <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                                        <span class="d-none d-sm-inline-block">Add New</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col p-0  table-responsive">
                                            <div class="overlay" *ngIf="progressMainTableNon">
                                                <div class="spinner-grow" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                            <table class="table table-striped table-hover valign-middle text-dark pb-5">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th *ngIf="currentUserRole == 'Client' || currentUserRole == 'Purchase'"> Department</th>
                                                        <th> Non Product</th>
                                                        <th> Quantity</th>
                                                        <th> Priority</th>
                                                        <th> Request Date</th>
                                                        <th *ngIf="currentUserRole != 'Client'"> Expected Arrival</th>
                                                        <th> Status</th>
                                                        <th> Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody *ngIf="requisitionListNon?.length != 0">
                                                    <tr *ngFor="let re of requisitionListNon; let ndx = index">
                                                        <td>{{ndx+pageNon+1}}</td>
                                                        <td *ngIf="currentUserRole == 'Client' || currentUserRole == 'Purchase'">{{ re.userName }}</td>
                                                        <td>{{ re.product_name }}</td>
                                                        <td>{{ re.qty }}</td>
                                                        <td>{{ re.priority }} Days</td>
                                                        <td>{{ re.created }}</td>
                                                        <td *ngIf="currentUserRole != 'Client'">{{ re.expected }}</td>
                                                        <td *ngIf="currentUserRole == 'Client'">
                                                            <div ngbDropdown container="body" *ngIf="re.status == 0">
                                                                <button ngbDropdownToggle class="btn btn-gradient-warning btn-sm" type="button" id="dropdownMenuButton101">
                                                                    Pending
                                                                </button>
                                                                <div ngbDropdownMenu aria-labelledby="dropdownMenuButton101">
                                                                    <a ngbDropdownItem (click)="changeStatusNon(re.id,'1')">Approve</a>
                                                                    <a ngbDropdownItem (click)="changeStatusNon(re.id,'2')">Reject</a>
                                                                </div>
                                                            </div>
                                                            <div ngbDropdown container="body" *ngIf="re.status == 1">
                                                                <button ngbDropdownToggle class="btn btn-gradient-success btn-sm" type="button" id="dropdownMenuButton101">
                                                                    Approve
                                                                </button>
                                                                <div ngbDropdownMenu aria-labelledby="dropdownMenuButton101">
                                                                    <a ngbDropdownItem (click)="changeStatusNon(re.id,'0')">Pending</a>
                                                                    <a ngbDropdownItem (click)="changeStatusNon(re.id,'2')">Reject</a>
                                                                </div>
                                                            </div>
                                                            <div ngbDropdown container="body" *ngIf="re.status == 2">
                                                                <button ngbDropdownToggle class="btn btn-gradient-danger btn-sm" type="button" id="dropdownMenuButton101">
                                                                    Reject
                                                                </button>
                                                                <div ngbDropdownMenu aria-labelledby="dropdownMenuButton101">
                                                                    <a ngbDropdownItem (click)="changeStatusNon(re.id,'1')">Approve</a>
                                                                    <a ngbDropdownItem (click)="changeStatusNon(re.id,'0')">Pending</a>
                                                                </div>
                                                            </div>
                                                            <span class="badge badge-pill badge-light-primary mr-1" *ngIf="re.status == 3">Completed</span>
                                                            <span class="badge badge-pill badge-light-info mr-1" *ngIf="re.status == 4">Processing</span>
                                                            <span class="badge badge-pill badge-light-secondary mr-1" *ngIf="re.status == 5">Received</span>
                                                        </td>
                                                        <td *ngIf="currentUserRole != 'Client'">
                                                            <span class="badge badge-pill badge-light-warning mr-1" *ngIf="re.status == 0">Pending</span>
                                                            <span class="badge badge-pill badge-light-success mr-1" *ngIf="re.status == 1">Approved</span>
                                                            <span class="badge badge-pill badge-light-danger mr-1" *ngIf="re.status == 2">Rejected</span>
                                                            <span class="badge badge-pill badge-light-primary mr-1" *ngIf="re.status == 3">Completed</span>
                                                            <span class="badge badge-pill badge-light-info mr-1" *ngIf="re.status == 4">Processing</span>
                                                            <span class="badge badge-pill badge-light-secondary mr-1" *ngIf="re.status == 5">Received</span>
                                                        </td>
                                                        <td>
                                                            <div ngbDropdown container="body" *ngIf="re.status == 0">
                                                                <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                                                                  <i data-feather="more-vertical"></i>
                                                                </button>
                                                                <div ngbDropdownMenu>
                                                                    <a ngbDropdownItem (click)="toggleSidebarNon('new-sidebar',re.id,ndx)"><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a>
                                                                    <a ngbDropdownItem (click)="deleteNon(re.id)"><i data-feather="trash" class="mr-50"></i><span>Delete</span></a>
                                                                </div>
                                                            </div>
                                                            <div class="p-1" *ngIf="re.status != 0">
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody *ngIf="requisitionListNon?.length == 0">
                                                    <tr *ngIf="!loadingNon">
                                                        <td colspan="9" class="no-data-available">No Data!</td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="9" class="no-data-available">
                                                            <div class="col" *ngIf="loadingNon">
                                                                <div class="text-center">
                                                                    <div class="spinner-border" role="status">
                                                                        <span class="sr-only">Loading...</span>
                                                                    </div>
                                                                    <div>
                                                                        Please Wait...
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <ngb-pagination class="mr-1 pull-right" [collectionSize]="collectionSizeNon" *ngIf="collectionSizeNon > 5" [(page)]="pageAdvancedEllipsesNon" [maxSize]="3" (pageChange)="loadPageNon($event)" [pageSize]="lengthsNon" [rotate]="true"></ngb-pagination>
                                        </div>
                                    </ng-template>
                                </li>
                            </ul>
                            <div [ngbNavOutlet]="nav" class="mt-2"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End for PR List  -->
            <!-- Start for Make PO  -->
            <div class="row match-height" *ngIf="makepo">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header w-100">
                            <div class="row m-0 w-100">
                                <div class="col-md-6 col-4 d-flex justify-content-start justify-content-md-star">
                                    <h4 class="card-title pt-50">Purchase Order</h4>
                                </div>
                                <div class="col-md-6 col-8 d-flex justify-content-start justify-content-md-end">
                                    <div class="d-flex w-100 align-items-end justify-content-end pr-1 pb-md-0">
                                        <button class="btn btn-secondary ml-1" rippleEffect (click)="canclePO()" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Make PO">
                                            <i data-feather="chevron-left" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block"><i data-feather="chevron-left"></i> Back</span>
                                        </button>
                                        <button class="btn btn-success ml-1" rippleEffect (click)="finalpo()" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Make PO">
                                            <i data-feather="chevron-right" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block">Next <i data-feather="chevron-right"></i> </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <div class="col p-0">
                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark pb-5">
                                    <thead>
                                        <tr>
                                            <th>
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" (change)="onCheckboxAllChangeFn($event)" [checked]="isAllSelected" id="rowChkbxRef_all" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Select/ Unselect All For PO">
                                                    <label class="custom-control-label" for="rowChkbxRef_all"></label>
                                                </div>
                                            </th>
                                            <th> Department</th>
                                            <th> Non Product</th>
                                            <th> Qty</th>
                                            <th> PO Qty</th>
                                            <th style="max-width: 200px;"> Expected Arrival</th>
                                            <th style="max-width: 200px;"> Vendor</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="makepoList?.length != 0">
                                        <tr *ngFor="let poRequisition of makepoList; let ndx = index">
                                            <td>
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input allbox" ngModel [(ngModel)]="poRequisition.isChecked" (ngModelChange)="filterResults($event)" value="{{ poRequisition.id }}" [checked]="isSelected" id="rowChkbxRef{{ ndx }}" />
                                                    <label class="custom-control-label" for="rowChkbxRef{{ ndx }}"></label>
                                                </div>
                                            </td>
                                            <td>Production</td>
                                            <td>{{ poRequisition.product_name }}</td>
                                            <td>{{ poRequisition.qty }}</td>
                                            <td style="max-width: 100px;">
                                                <core-touchspin [numberValue]="poRequisition.qty" (onChange)="countChange($event,ndx)" size="sm" [stepValue]="1" [minValue]="1" [maxValue]="poRequisition.qty"></core-touchspin>
                                            </td>
                                            <td style="max-width: 200px;">
                                                <input type="text" name="date" style="max-width: 200px;" [(ngModel)]="poRequisition.date" placeholder="dd-mm-yyyy" class="form-control" (click)="basicDP.toggle()" [(ngModel)]="basicDPdata[ndx]" ngbDatepicker #basicDP="ngbDatepicker" />
                                            </td>
                                            <td style="max-width:200px;">
                                                <ng-select name="raw_material_product" [(ngModel)]="poRequisition.vendor" style="max-width: 200px;" container="body">
                                                    <ng-option *ngFor="let vendor of poRequisition.vendors" [value]="vendor.id">{{ vendor.name }} ({{ vendor.mobile }})</ng-option>
                                                </ng-select>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="makepoList?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="9" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="9" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <!-- End for Make PO  -->
            <!-- Start for Make NonPO  -->
            <div class="row match-height" *ngIf="makepoNon">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header w-100">
                            <div class="row m-0 w-100">
                                <div class="col-md-6 col-4 d-flex justify-content-start justify-content-md-star">
                                    <h4 class="card-title pt-50">Purchase Order</h4>
                                </div>
                                <div class="col-md-6 col-8 d-flex justify-content-start justify-content-md-end">
                                    <div class="d-flex w-100 align-items-end justify-content-end pr-1 pb-md-0">
                                        <button class="btn btn-secondary ml-1" rippleEffect (click)="cancelPONon()" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Make PO">
                                            <i data-feather="chevron-left" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block"><i data-feather="chevron-left"></i> Back</span>
                                        </button>
                                        <button class="btn btn-success ml-1" rippleEffect (click)="finalPoNon()" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Make PO">
                                            <i data-feather="chevron-right" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block">Next <i data-feather="chevron-right"></i> </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <div class="col p-0">
                                <div class="overlay" *ngIf="progressMainTableNon">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark pb-5">
                                    <thead>
                                        <tr>
                                            <th>
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" (change)="onCheckboxAllChangeFnNon($event)" [checked]="isAllSelectedNon" id="rowChkbxRef_all" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Select/ Unselect All For PO">
                                                    <label class="custom-control-label" for="rowChkbxRef_all"></label>
                                                </div>
                                            </th>
                                            <th> Department</th>
                                            <th> Raw Material Product</th>
                                            <th> Qty</th>
                                            <th> PO Qty</th>
                                            <th style="max-width: 200px;"> Expected Arrival</th>
                                            <th style="max-width: 300px;"> Vendor</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="makeFinalListNon?.length != 0">
                                        <tr *ngFor="let mpl of makeFinalListNon; let ndx = index">
                                            <td>
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input allbox" ngModel [(ngModel)]="mpl.isChecked" (ngModelChange)="filterResultsNon($event)" value="{{ mpl.id }}" [checked]="isSelectedNon" id="rowChkbxRef{{ ndx }}" />
                                                    <label class="custom-control-label" for="rowChkbxRef{{ ndx }}"></label>
                                                </div>
                                            </td>
                                            <td>Production</td>
                                            <td>{{ mpl.product_name }}</td>
                                            <td>{{ mpl.qty }}</td>
                                            <td style="max-width: 100px;">
                                                <core-touchspin [numberValue]="mpl.qty" (onChange)="countChangeNon($event,ndx)" size="sm" [stepValue]="1" [minValue]="1" [maxValue]="mpl.qty"></core-touchspin>
                                            </td>
                                            <td style="max-width: 200px;">
                                                <input type="text" name="date" style="max-width: 200px;" [(ngModel)]="mpl.date" placeholder="dd-mm-yyyy" class="form-control" (click)="basicDP.toggle()" [(ngModel)]="basicDPdata[ndx]" ngbDatepicker #basicDP="ngbDatepicker" />
                                            </td>
                                            <td style="max-width:200px;">
                                                <ng-select name="vendor_id" [(ngModel)]="mpl.vendor" style="max-width: 200px;" container="body">
                                                    <ng-option *ngFor="let vendor of vendorList" [value]="vendor.id">{{ vendor.name }} ({{ vendor.mobile }})</ng-option>
                                                </ng-select>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="makeFinalListNon?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="9" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="9" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End for Make NonPO -->

            <!-- Start for Final PO  -->
            <div class="row match-height" *ngIf="finalPO">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header w-100">
                            <div class="row m-0 w-100">
                                <div class="col-md-6 col-4 d-flex justify-content-start justify-content-md-star">
                                    <h4 class="card-title pt-50">Purchase Order</h4>
                                </div>
                                <div class="col-md-6 col-8 d-flex justify-content-start justify-content-md-end">
                                    <div class="d-flex w-100 align-items-end justify-content-end pr-1 pb-md-0">
                                        <button class="btn btn-secondary ml-1" rippleEffect (click)="canclefinalpo()" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Back to Make PO">
                                            <i data-feather="chevron-left" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block"><i data-feather="chevron-left"></i> Back</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col" *ngIf="loading">
                    <div class="text-center">
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div>
                            Please Wait...
                        </div>
                    </div>
                </div>

                <div class="col-md-6 col-12" *ngFor="let final of finalData; let ndx = index">
                    <div class="card">
                        <div class="card-header w-100">
                            <div class="row w-100">
                                <div class="col-6">
                                    <h4 class="card-title pt-50">
                                        {{ final.vendor.name }}
                                        <br/>
                                        <small>{{ final.vendor.mobile }}</small>
                                    </h4>
                                </div>
                                <div class="col-6">
                                    <small>{{ final.vendor.address }}</small>
                                </div>
                            </div>

                        </div>
                        <div class="card-body p-0 pb-1">

                            <table class="table table-striped table-hover valign-middle text-dark pb-5">
                                <thead>
                                    <tr>
                                        <th> Raw Material Product</th>
                                        <th> Price</th>
                                        <th> Qty</th>
                                        <th> Total</th>
                                        <th> Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let product of final.product; let subndx = index">
                                        <td>{{ product.product_name }}</td>
                                        <td>{{ product.price }}</td>
                                        <td>{{ product.po_qty }}</td>
                                        <td>{{ product.price * product.po_qty }}</td>
                                        <td>
                                            <button class="btn btn-danger btn-xs" (click)="deleteSingle(ndx,subndx)" type="button"><i data-feather="trash"></i></button>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                            <div class="col  pt-1">
                                <textarea class="form-control" rows="4" id="remarks" [(ngModel)]="final.remarks" placeholder="Remark If Any"></textarea>
                            </div>
                            <div class="col  pt-1">
                                <button class="btn btn-primary btn-block" type="button" (click)="savePO(ndx)" rippleEffect [disabled]="loadingForm"> <span *ngIf="loadingForm" class="spinner-border spinner-border-sm mr-1 "></span> <i data-feather="save"></i> Save
                                </button>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <!-- End for Final PO  -->
            <!-- Start for Final PO  -->
            <div class="row match-height" *ngIf="finalPONon">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header w-100">
                            <div class="row m-0 w-100">
                                <div class="col-md-6 col-4 d-flex justify-content-start justify-content-md-star">
                                    <h4 class="card-title pt-50">Purchase Order</h4>
                                </div>
                                <div class="col-md-6 col-8 d-flex justify-content-start justify-content-md-end">
                                    <div class="d-flex w-100 align-items-end justify-content-end pr-1 pb-md-0">
                                        <button class="btn btn-secondary ml-1" rippleEffect (click)="cancelFinalPoNon()" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Back to Make PO">
                                            <i data-feather="chevron-left" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block"><i data-feather="chevron-left"></i> Back</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col" *ngIf="loadingNon">
                    <div class="text-center">
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div>
                            Please Wait...
                        </div>
                    </div>
                </div>

                <div class="col-md-6 col-12" *ngFor="let final of finalDataNon; let ndx = index">
                    <div class="card">
                        <div class="card-header w-100">
                            <div class="row w-100">
                                <div class="col-6">
                                    <h4 class="card-title pt-50">
                                        {{ final.vendor.name }}
                                        <br/>
                                        <small>{{ final.vendor.mobile }}</small>
                                    </h4>
                                </div>
                                <div class="col-6">
                                    <small>{{ final.vendor.address }}</small>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <table class="table table-striped table-hover valign-middle text-dark pb-5">
                                <thead>
                                    <tr>
                                        <th> Product</th>
                                        <th> Price</th>
                                        <th> Qty</th>
                                        <th> Total</th>
                                        <th> Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let product of final.product; let subndx = index">
                                        <td>{{ product.product_name }}</td>
                                        <td>{{ product.price }}</td>
                                        <td>{{ product.po_qty }}</td>
                                        <td>{{ product.price * product.po_qty }}</td>
                                        <td>
                                            <button class="btn btn-danger btn-xs" (click)="deleteSingleNon(ndx,subndx)" type="button"><i data-feather="trash"></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="col  pt-1">
                                <textarea class="form-control" rows="4" id="remarks" [(ngModel)]="final.remarks" placeholder="Remark If Any"></textarea>
                            </div>
                            <div class="col  pt-1">
                                <button class="btn btn-primary btn-block" type="button" (click)="savePONon(ndx)" rippleEffect [disabled]="loadingFormNon"> <span *ngIf="loadingFormNon" class="spinner-border spinner-border-sm mr-1 "></span> <i data-feather="save"></i> Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End for Final PO  -->
        </section>
    </div>
</div>


<!-- New User Sidebar -->
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="new-sidebar" overlayClass="modal-backdrop">
    <div class="slideout-content">
        <!-- Modal to add new starts-->
        <div class="modalsd modal-slide-in sdfade new-modal" id="modals-slide-in">
            <div class="modal-dialog">
                <form class="add-new modal-content pt-0" autocomplete="off" (ngSubmit)="showRowProductName ? onSubmit() : onNonSubmit()" [formGroup]="newForm">
                    <button type="button" class="close" *ngIf="showRowProductName" data-dismiss="modal" aria-label="Close" (click)="toggleSidebar('new-sidebar')">
                      ×
                    </button>
                    <button type="button" class="close" *ngIf="showProductName" data-dismiss="modal" aria-label="Close" (click)="toggleSidebarNon('new-sidebar')">
                        ×
                      </button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title" id="exampleModalLabel">
                            <span *ngIf="showProductName">Non Product</span> Purchase Requisition
                        </h5>
                    </div>
                    <div class="modal-body flex-grow-1 ">

                        <div class="row m-0">
                            <div class="col">
                                <div class="form-group" *ngIf="showRowProductName">
                                    <label for="raw_material_product" class="form-label">Raw Material Product<span class="text-danger">*</span></label>
                                    <ng-select name="raw_material_product" formControlName="raw_material_product_id">
                                        <ng-option disabled selected value="0">Select Row Material Product</ng-option>
                                        <ng-option *ngFor="let rowMaterialProducts of rowMaterialProductsDropDown" [value]="rowMaterialProducts.id">{{rowMaterialProducts.product_name}}</ng-option>
                                    </ng-select>
                                </div>

                                <div class="form-group" *ngIf="showProductName">
                                    <label for="product_name" class="form-label">Name<span class="text-danger">*</span> </label>
                                    <input type="text" formControlName="product_name" class="form-control" placeholder="Product Name" [ngClass]="{ 'is-invalid': submitted && f.product_name.errors }"/>
                                    <div *ngIf="submitted && f.product_name.errors" class="invalid-feedback">
                                        <div *ngIf="f.product_name.errors.required">Name is required</div>
                                    </div>
                                </div>

                                <div class="form-group" *ngIf="showProductName">
                                    <label for="price" class="form-label">Price<span class="text-danger">*</span> </label>
                                    <input type="number" formControlName="price" class="form-control" placeholder="Product Name" [ngClass]="{ 'is-invalid': submitted && f.price.errors }"/>
                                    <div *ngIf="submitted && f.price.errors" class="invalid-feedback">
                                        <div *ngIf="f.price.errors.required">Price is required</div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="quantity" class="form-label">Quantity<span class="text-danger">*</span></label>
                                    <input type="number" class="form-control" formControlName="qty" id="quantity" placeholder="Enter Quantity" />
                                </div>
                                <div class="form-group">
                                    <label for="priority" class="form-label">Priority <small class="text-muted">(In Days)</small><span class="text-danger">*</span></label>
                                    <input type="number" class="form-control" formControlName="priority" id="priority" placeholder="Enter Quantity" />
                                </div>
                                <div class="clearfix"></div>
                                <div class="col">
                                    <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                                        <div class="alert-body">
                                            <p>{{ error }}</p>
                                        </div>
                                    </ngb-alert>
                                </div>
                                <button type="submit" class="btn btn-primary mr-1 " rippleEffect [disabled]="loadingForm"> <span *ngIf="loadingForm" class="spinner-border spinner-border-sm mr-1 "></span>Submit</button>
                                <button type="reset" class="btn btn-secondary" (click)="toggleSidebar('new-sidebar')" [disabled]="loadingForm" rippleEffect>
                                    <span *ngIf="loadingForm" class="spinner-border spinner-border-sm mr-1"></span>
                                    <i data-feather="x" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                    <span class="d-none d-sm-inline-block">Cancel </span>                                    
                              </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- Modal to add new Ends-->
    </div>
</core-sidebar>
<!--/ New User Sidebar -->