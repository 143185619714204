<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="col p-0">

                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Delivery Challan Add</h4>
                    </div>
                    <div class="card-body p-0 pb-1">
                        <div class="row m-0">
                            <div class="form-group col-lg-6 " *ngIf="!viewDealerName">
                                <label class="form-label">Customer Name<span class="text-danger">*</span></label>
                                <ng-autocomplete #ngAutoCompleteStatic [data]="allDealer" name="dealers" [initialValue]="" [minQueryLength]="3" placeholder="Search Customers Min 3 Characters " [searchKeyword]="keyword" historyIdentifier="allDealer" [historyHeading]="historyHeading"
                                    [historyListMaxNumber]="3" notFoundText="Not found" (selected)='selectEvent($event)' (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplateStatic" [isLoading]="isLoading"
                                    [notFoundTemplate]="notFoundTemplate">
                                </ng-autocomplete>
                                <ng-template #itemTemplateStatic let-item>
                                    <a [innerHTML]="item.name"></a>
                                </ng-template>
                                <ng-template #notFoundTemplate let-notFound>
                                    <div [innerHTML]="notFound"></div>
                                </ng-template>
                            </div>
                            <div class="form-group col-lg-6 " *ngIf="!loadingViewDealerName && viewDealerName">
                                <label class="form-label">Customer Name<span class="text-danger">*</span></label>
                                <h4 class="text-bold">{{ dealerName }}</h4>
                            </div>
                            <div class="form-group col-lg-6 text-right pt-2" *ngIf="lr_form">
                                <button type="button" [disabled]="loading" class="btn btn-outline-dark" (click)="backDispatchProducts()"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Back</button>
                            </div>
                            <div class="form-group col-lg-6 text-right pt-2" *ngIf="set_order && orderDeliveryOrder?.length != 0">
                                <button name="submit" type="button" class="btn btn-success" (click)="previewDispatchProducts()">Save & Next</button>
                            </div>

                            <div class="form-group col-lg-12" *ngIf="viewDealerName && loadingViewDealerName">
                                <div class="text-center">
                                    <div class="spinner-border" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <div>
                                        Please Wait...
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card card-topline-dark order-details-table" *ngIf="set_order && orderDeliveryOrder?.length != 0">
                    <div class="card-body  p-0 pb-1">
                        <div class="col">
                            <div class="row mt-2" *ngFor="let orderdetail of orderDeliveryOrder; let key = index">
                                <div class="col-3">
                                    Order ID : #<b>{{ orderdetail.invoice_no }}</b>
                                </div>

                                <div class="col-3">
                                    PO No : #<b>{{ orderdetail.po_number }}</b>
                                </div>
                                <div class="col-6 text-right">
                                    Date : <b>{{ orderdetail.order_date}}</b>
                                </div>
                                <div class="col mt-2 table-responsive">
                                    <table class="table table-bordered ">
                                        <tr>
                                            <th style="width:50%"> Description</th>
                                            <th style="width:10%">Qty</th>
                                            <th style="width:25%">Dispatch Qty</th>
                                            <th style="width:15%">Remaining Qty</th>
                                        </tr>
                                        <tr *ngFor="let orderProducts of orderdetail.order_details; let key = index">
                                            <td>{{ orderProducts.product_code }} {{ orderProducts.product_name }} {{ orderProducts.size_title }} {{ orderProducts.color_title }} </td>
                                            <td>{{ orderProducts.order_qty }} </td>
                                            <td>
                                                <core-touchspin [numberValue]="orderProducts.dispatch_qty" size="lg" [stepValue]="1" [minValue]="0" [maxValue]="1000" (onChange)="countChange($event,orderdetail.id,orderProducts.id)"></core-touchspin>
                                            </td>
                                            <td>{{ orderProducts.remaining_qty }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="col text-right">
                            <button name="submit" type="button" class="btn btn-success" (click)="previewDispatchProducts()">Save & Next</button>
                        </div>
                    </div>
                </div>

                <div class="card card-topline-dark order-details-table" *ngIf="lr_form">
                    <div class="card-body p-0 pb-1">
                        <div class="col">
                            <form name="form" [formGroup]="newForm" (ngSubmit)="onSubmit()" autocomplete="off">
                                <div class="row mt-2">

                                    <div class="form-group col-lg-4">
                                        <label for="register-transport_id" class="form-label">Select Transport</label>
                                        <ng-select aria-describedby="register-transport_id" formControlName="transport_id" (change)="changeTransport($event)" tabindex="1">
                                            <ng-option [value]="" selected disabled>Select Transport</ng-option>
                                            <ng-option *ngFor="let transport of transportDropDown" [value]="transport.id">{{transport.name}}</ng-option>

                                        </ng-select>
                                    </div>

                                    <div class="form-group col-lg-4 d-none ">
                                        <label for="register-transport_name" class="form-label">Transport Name</label>
                                        <input type="text" formControlName="transport_name" class="form-control" placeholder="Enter Transport Name" aria-describedby="register-transport_name" tabindex="2" [ngClass]="{ 'is-invalid': submitted && f.transport_name.errors }" />
                                    </div>
                                    <div class="form-group col-lg-4 d-none">
                                        <label for="register-mobile_no" class="form-label">Mobile</label>
                                        <input type="text" formControlName="mobile_no" class="form-control" placeholder="Enter Mobile" aria-describedby="register-mobile_no" tabindex="3" [ngClass]="{ 'is-invalid': submitted && f.mobile_no.errors }" />
                                    </div>
                                    <div class="form-group col-lg-4 ">
                                        <label for="register-lr_no" class="form-label">LR No</label>
                                        <input type="text" formControlName="lr_no" class="form-control" placeholder="Enter LR No" aria-describedby="register-lr_no" tabindex="4" [ngClass]="{ 'is-invalid': submitted && f.lr_no.errors }" />
                                    </div>
                                    <div class="form-group col-lg-4 ">
                                        <label for="register-vehicle_no" class="form-label">Vehicle No</label>
                                        <input type="text" formControlName="vehicle_no" class="form-control" placeholder="Enter Vehicle No" aria-describedby="register-vehicle_no" tabindex="5" [ngClass]="{ 'is-invalid': submitted && f.vehicle_no.errors }" />
                                    </div>
                                    <div class="form-group col-lg-12 ">
                                        <label for="register-remarks" class="form-label">Remarks</label>
                                        <textarea rows="3" formControlName="remarks" class="form-control" placeholder="Enter Remarks" aria-describedby="register-remarks" tabindex="6" [ngClass]="{ 'is-invalid': submitted && f.remarks.errors }"></textarea>
                                    </div>

                                    <div class="form-group col-lg-12 table-responsive">
                                        <table class="table table-bordered ">
                                            <tr>
                                                <th style="width: 350px;" rowspan="2">Description</th>
                                                <th style="width: 130px;" rowspan="2">Rate</th>
                                                <th rowspan="2">Qty</th>
                                                <th colspan="2" class="text-center">Discount</th>
                                                <th rowspan="2">Taxable</th>
                                                <th colspan="2" *ngIf="dealerGST == 'C&S' || dealerGST == 'UT'" class="text-center">CGST</th>
                                                <th class="text-center" *ngIf="dealerGST == 'C&S'" colspan="2">SGST</th>
                                                <th class="text-center" *ngIf="dealerGST == 'UT'" colspan="2">UTGST</th>
                                                <th class="text-center" *ngIf="dealerGST == 'I'" colspan="2">IGST</th>
                                                <th class="text-center" colspan="2">Other Tax</th>
                                                <th rowspan="2">Total</th>
                                            </tr>
                                            <tr>
                                                <th>%</th>
                                                <th>Amt</th>
                                                <th *ngIf="dealerGST == 'C&S' || dealerGST == 'UT'">%</th>
                                                <th *ngIf="dealerGST == 'C&S' || dealerGST == 'UT'">Amt</th>
                                                <th>%</th>
                                                <th>Amt</th>
                                                <th>%</th>
                                                <th>Amt</th>
                                            </tr>
                                            <tr *ngFor="let dispatchProducts of dispatchProductsNewFinalOrder; let key = index">
                                                <td>{{ dispatchProducts.product_code }} {{ dispatchProducts.product_name }} {{ dispatchProducts.size_title }} {{ dispatchProducts.color_title }}</td>
                                                <td>
                                                    <input type="number" value="{{ dispatchProducts.sales_price.toFixed(3) }}" min="0" name="new_sales_price" class="form-control" (keyup)="priceChange($event,dispatchProducts.product_id,key)" />
                                                </td>
                                                <td>
                                                    {{ dispatchProducts.dispatch_qty }}
                                                </td>
                                                <td>
                                                    {{ dispatchProducts.discount }} %
                                                </td>
                                                <td>
                                                    ₹ {{ dispatchProducts.discount_amt.toFixed(3) }}
                                                </td>
                                                <td>
                                                    ₹ {{ dispatchProducts.taxable.toFixed(3) }}
                                                </td>
                                                <td *ngIf="dealerGST == 'C&S' || dealerGST == 'UT'">
                                                    {{ dispatchProducts.cgst_per }} %
                                                </td>
                                                <td *ngIf="dealerGST == 'C&S' || dealerGST == 'UT'">
                                                    ₹ {{ dispatchProducts.cgst_amt.toFixed(3) }}
                                                </td>
                                                <td *ngIf="dealerGST == 'C&S' || dealerGST == 'UT'">
                                                    {{ dispatchProducts.sgst_per }} %
                                                </td>
                                                <td *ngIf="dealerGST == 'C&S' || dealerGST == 'UT'">
                                                    ₹ {{ dispatchProducts.sgst_amt.toFixed(3) }}
                                                </td>
                                                <td *ngIf="dealerGST == 'I'">
                                                    {{ dispatchProducts.igst_per }} %
                                                </td>
                                                <td *ngIf="dealerGST == 'I'">
                                                    ₹ {{ dispatchProducts.igst_amt.toFixed(3) }}
                                                </td>
                                                <td>
                                                    {{ dispatchProducts.other_tax_per }} %
                                                </td>
                                                <td>
                                                    ₹ {{ dispatchProducts.other_tax_amt.toFixed(3) }}
                                                </td>
                                                <td>{{ dispatchProducts.sub_total.toFixed(3) }}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="13" class="p-0">
                                                    <table class="table">
                                                        <tr>
                                                            <td colspan="2" class="text-right">Sub Total</td>
                                                            <td style="width:150px;" class="text-right"> ₹ {{ getSum('taxable').toFixed(3) }}</td>
                                                        </tr>
                                                        <!-- <tr>
                                                            <td colspan="2" class="text-right">Discount</td>
                                                            <td class="text-right"> ₹ <span class="main_discount"> {{ getSum('discount_amt').toFixed(3) }}</span> </td>
                                                        </tr> -->
                                                        <tr *ngIf="dealerGST == 'C&S' || dealerGST == 'UT'">
                                                            <td colspan="2" class="text-right">CGST</td>
                                                            <td class="text-right"> ₹ <span class="tax_one_amt">{{ getSum('cgst_amt').toFixed(3) }}</span> </td>
                                                        </tr>
                                                        <tr *ngIf="dealerGST == 'C&S'">
                                                            <td colspan="2" class="text-right">SGST</td>
                                                            <td class="text-right"> ₹ <span class="tax_two_amt">{{ getSum('sgst_amt').toFixed(3) }}</span> </td>
                                                        </tr>
                                                        <tr *ngIf="dealerGST == 'UT'">
                                                            <td colspan="2" class="text-right">UTGST</td>
                                                            <td class="text-right"> ₹ <span class="tax_two_amt">{{ getSum('sgst_amt').toFixed(3) }}</span> </td>
                                                        </tr>
                                                        <tr *ngIf="dealerGST == 'I'">
                                                            <td colspan="2" class="text-right">IGST</td>
                                                            <td class="text-right"> ₹ <span class="tax_two_amt">{{ getSum('igst_amt').toFixed(3) }}</span> </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="2" class="text-right">Other Tax</td>
                                                            <td class="text-right"> ₹ <span class="tax_two_amt">{{ getSum('other_tax_amt').toFixed(3) }}</span> </td>
                                                        </tr>
                                                        <!-- <tr>
                                                            <td colspan="2" class="text-right">Round Off</td>
                                                            <td class="text-right"> ₹ <span class="grand_total">{{ round_off.toFixed(3) }}</span> </td>
                                                        </tr> -->
                                                        <tr>
                                                            <td colspan="2" class="text-right">Grand Total</td>
                                                            <td class="text-right"> ₹ <span class="grand_total">{{ (getSum('taxable') + getSum('igst_amt') + getSum('other_tax_amt')).toFixed(3) }}</span> </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="col col-sm-4 col-xs-4 col-lg-2 pt-1">
                                        <button type="button" [disabled]="loading" class="btn btn-outline-dark btn-block" (click)="backDispatchProducts()"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Back</button>
                                    </div>
                                    <div class="col col-sm-12 col-xs-12 col-lg-6">
                                        <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                                            <div class="alert-body">
                                                <p>{{ error }}</p>
                                            </div>
                                        </ngb-alert>
                                    </div>



                                    <div class="col col-sm-4 col-xs-4 col-lg-2 pt-1">
                                        <button [disabled]="loading" type="button" routerLink="/delivery-challan" class="btn btn-secondary btn-block" tabindex="15" rippleEffect>
                                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Cancel
                                        </button>
                                    </div>
                                    <div class="col col-sm-4 col-xs-4 col-lg-2 pt-1">
                                        <button [disabled]="loading" class="btn btn-primary btn-block" tabindex="14" rippleEffect>
                                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Save
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>


            </div>
        </section>
    </div>
</div>