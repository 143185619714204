<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Purchase Order List</h4>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <ul ngbNav #nav="ngbNav" class="nav-tabs px-1">
                                <li ngbNavItem>
                                    <a ngbNavLink>Product PO</a>
                                    <ng-template ngbNavContent>
                                        <div class="row m-0">
                                            <div class="col-md-3 col-6">
                                                <div class="d-flex justify-content-between align-items-center ml-1">
                                                    <label class="d-flex align-items-center">Show
                                                    <select class="form-control mx-25" [(ngModel)]="lengths" (change)="loadPageLenghs($event)">
                                                        <option value="30">30</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                    </select>
                                                  </label>
                                                </div>
                                            </div>
            
                                            <div class="col-md-9 col-6 d-flex justify-content-start justify-content-md-end pb-1">
                                                <div class="d-flex align-items-end justify-content-end pr-1 pb-1 pb-md-0">
                                                    <button class="btn btn-primary ml-1" rippleEffect routerLink="/purchase-order/add" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Add New Order" *ngIf="currentUserRole == 'Purchase'">
                                                        <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                                        <span class="d-none d-sm-inline-block">Add New</span>
                                                    </button>
            
                                                    <button class="btn btn-primary ml-1" rippleEffect (click)="toggleSidebar('new-sidebar')">
                                                        <i data-feather="filter" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                                        <span class="d-none d-sm-inline-block">Filter</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col p-0 table-responsive">
                                            <div class="overlay" *ngIf="progressMainTable">
                                                <div class="spinner-grow" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                            <table class="table table-striped table-hover valign-middle text-dark">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th> PO No.</th>
                                                        <th> PO Date</th>
                                                        <th> Vendor Name</th>
                                                        <th> Amount</th>
                                                        <th> Status</th>
                                                        <th> Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody *ngIf="productsList?.length != 0">
                                                    <tr *ngFor="let product of productsList; let ndx = index">
                                                        <td>{{ndx+page+1}}</td>
                                                        <td>{{ product.po_number }}</td>
                                                        <td>{{ product.po_date }}</td>
                                                        <td>{{ product.name }}</td>
                                                        <td>₹ {{ product.total_amount | number:'1.2-2' }}</td>
                                                        <td>
                                                            <div ngbDropdown *ngIf="product.status == 0 && currentUserRole != 'Purchase'">
                                                                <button ngbDropdownToggle class="btn btn-gradient-warning btn-sm" type="button" id="dropdownMenuButton101">
                                                                    Processing
                                                              </button>
                                                                <div ngbDropdownMenu aria-labelledby="dropdownMenuButton101">
                                                                    <a ngbDropdownItem (click)="orderchangeStatus(product.id,4)">Approved</a>
                                                                    <a ngbDropdownItem (click)="orderchangeStatus(product.id,2)">Canceled</a>
                                                                </div>
                                                            </div>
            
                                                            <div ngbDropdown *ngIf="product.status == 4 && currentUserRole != 'Purchase'">
                                                                <button ngbDropdownToggle class="btn btn-gradient-success btn-sm" type="button" id="dropdownMenuButton101">
                                                                    Approved
                                                              </button>
                                                                <div ngbDropdownMenu aria-labelledby="dropdownMenuButton101">
                                                                    <a ngbDropdownItem (click)="orderchangeStatus(product.id,0)">Processing</a>
                                                                    <a ngbDropdownItem (click)="orderchangeStatus(product.id,2)">Canceled</a>
                                                                </div>
                                                            </div>
            
                                                            <div ngbDropdown *ngIf="product.status == 2 && currentUserRole != 'Purchase'">
                                                                <button ngbDropdownToggle class="btn btn-gradient-danger btn-sm" type="button" id="dropdownMenuButton101">
                                                                    Canceled
                                                              </button>
                                                                <div ngbDropdownMenu aria-labelledby="dropdownMenuButton101">
                                                                    <a ngbDropdownItem (click)="orderchangeStatus(product.id,0)">Processing</a>
                                                                    <a ngbDropdownItem (click)="orderchangeStatus(product.id,4)">Approved</a>
                                                                </div>
                                                            </div>
            
                                                            <span class="badge badge-pill badge-light-warning mr-1" *ngIf="product.status == 0 && currentUserRole == 'Purchase'">Processing</span>
                                                            <span class="badge badge-pill badge-light-success mr-1" *ngIf="product.status == 4 && currentUserRole == 'Purchase'">Approved</span>
                                                            <span class="badge badge-pill badge-light-danger mr-1" *ngIf="product.status == 2 && currentUserRole == 'Purchase'">Canceled</span>
            
                                                            <span class="badge badge-pill badge-light-primary mr-1" *ngIf="product.status == 1">Received</span>
                                                            <span class="badge badge-pill badge-light-info mr-1" *ngIf="product.status == 3">Half Receive</span>
                                                        </td>
                                                        <td>
                                                            <div ngbDropdown container="body">
                                                                <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                                                                  <i data-feather="more-vertical"></i>
                                                                </button>
                                                                <div ngbDropdownMenu>
                                                                    <a ngbDropdownItem (click)="download(product.id,ndx)"><i data-feather="download" class="mr-50"></i><span>Download</span></a>
                                                                    <a ngbDropdownItem (click)="modalOpen(modalBasic,product.id,'view')"><i data-feather="eye" class="mr-50"></i><span>View Details</span></a>
                                                                    <a ngbDropdownItem *ngIf="product.status == 4 || product.status == 3" (click)="modalOpen(modalReceive,product.id,'receive')"><i data-feather="truck" class="mr-50"></i><span>Receive</span></a>
                                                                    <a ngbDropdownItem *ngIf="product.status == 2 || product.status == 0 || product.status == 4" (click)="delete(product.id)"><i data-feather="trash" class="mr-50"></i><span>Delete</span></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody *ngIf="productsList?.length == 0">
                                                    <tr *ngIf="!loading">
                                                        <td colspan="8" class="no-data-available">No Data!</td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="8" class="no-data-available">
                                                            <div class="col" *ngIf="loading">
                                                                <div class="text-center">
                                                                    <div class="spinner-border" role="status">
                                                                        <span class="sr-only">Loading...</span>
                                                                    </div>
                                                                    <div>
                                                                        Please Wait...
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <ngb-pagination class="mr-1 pull-right" [collectionSize]="collectionSize" *ngIf="collectionSize > 5" [(page)]="pageBasicText" [maxSize]="3" (pageChange)="loadPage($event)" [pageSize]="lengths" [rotate]="true"></ngb-pagination>
                                        </div>
                                    </ng-template>
                                </li>
                                <li ngbNavItem>
                                    <a ngbNavLink>Non Product PO</a>
                                    <ng-template ngbNavContent>
                                        <div class="row m-0">
                                            <div class="col-md-3 col-6">
                                                <div class="d-flex justify-content-between align-items-center ml-1">
                                                    <label class="d-flex align-items-center">Show
                                                    <select class="form-control mx-25" [(ngModel)]="lengthsNon" (change)="loadPageLengthsNon($event)">
                                                        <option value="30">30</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                    </select>
                                                  </label>
                                                </div>
                                            </div>
            
                                            <div class="col-md-9 col-6 d-flex justify-content-start justify-content-md-end pb-1">
                                                <div class="d-flex align-items-end justify-content-end pr-1 pb-1 pb-md-0">
                                                    <button class="btn btn-primary ml-1" rippleEffect routerLink="/purchase-non-order/add" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Add New Order" *ngIf="currentUserRole == 'Purchase'">
                                                        <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                                        <span class="d-none d-sm-inline-block">Add New</span>
                                                    </button>
            
                                                    <button class="btn btn-primary ml-1" rippleEffect (click)="toggleSidebarNon('new-sidebar')">
                                                        <i data-feather="filter" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                                        <span class="d-none d-sm-inline-block">Filter</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col p-0 table-responsive">
                                            <div class="overlay" *ngIf="progressMainTableNon">
                                                <div class="spinner-grow" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                            <table class="table table-striped table-hover valign-middle text-dark">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th> PO No.</th>
                                                        <th> PO Date</th>
                                                        <th> Vendor Name</th>
                                                        <th> Amount</th>
                                                        <th> Status</th>
                                                        <th> Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody *ngIf="purchaseOrderListNon?.length != 0">
                                                    <tr *ngFor="let prn of purchaseOrderListNon; let ndx = index">
                                                        <td>{{ndx+pageNon+1}}</td>
                                                        <td>{{ prn.po_number }}</td>
                                                        <td>{{ prn.po_date }}</td>
                                                        <td>{{ prn.name }}</td>
                                                        <td>₹ {{ prn.total_amount | number:'1.2-2' }}</td>
                                                        <td>
                                                            <div ngbDropdown container="body" *ngIf="prn.status == 0 && currentUserRole != 'Purchase'">
                                                                <button ngbDropdownToggle class="btn btn-gradient-warning btn-sm" type="button" id="dropdownMenuButton101">
                                                                    Processing
                                                              </button>
                                                                <div ngbDropdownMenu aria-labelledby="dropdownMenuButton101">
                                                                    <a ngbDropdownItem (click)="orderChangeStatusNon(prn.id,4)">Approved</a>
                                                                    <a ngbDropdownItem (click)="orderChangeStatusNon(prn.id,2)">Canceled</a>
                                                                </div>
                                                            </div>
            
                                                            <div ngbDropdown container="body" *ngIf="prn.status == 4 && currentUserRole != 'Purchase'">
                                                                <button ngbDropdownToggle class="btn btn-gradient-success btn-sm" type="button" id="dropdownMenuButton101">
                                                                    Approved
                                                              </button>
                                                                <div ngbDropdownMenu aria-labelledby="dropdownMenuButton101">
                                                                    <a ngbDropdownItem (click)="orderChangeStatusNon(prn.id,0)">Processing</a>
                                                                    <a ngbDropdownItem (click)="orderChangeStatusNon(prn.id,2)">Canceled</a>
                                                                </div>
                                                            </div>
            
                                                            <div ngbDropdown container="body" *ngIf="prn.status == 2 && currentUserRole != 'Purchase'">
                                                                <button ngbDropdownToggle class="btn btn-gradient-danger btn-sm" type="button" id="dropdownMenuButton101">
                                                                    Canceled
                                                              </button>
                                                                <div ngbDropdownMenu aria-labelledby="dropdownMenuButton101">
                                                                    <a ngbDropdownItem (click)="orderChangeStatusNon(prn.id,0)">Processing</a>
                                                                    <a ngbDropdownItem (click)="orderChangeStatusNon(prn.id,4)">Approved</a>
                                                                </div>
                                                            </div>
            
                                                            <span class="badge badge-pill badge-light-warning mr-1" *ngIf="prn.status == 0 && currentUserRole == 'Purchase'">Processing</span>
                                                            <span class="badge badge-pill badge-light-success mr-1" *ngIf="prn.status == 4 && currentUserRole == 'Purchase'">Approved</span>
                                                            <span class="badge badge-pill badge-light-danger mr-1" *ngIf="prn.status == 2 && currentUserRole == 'Purchase'">Canceled</span>
            
                                                            <span class="badge badge-pill badge-light-primary mr-1" *ngIf="prn.status == 1">Received</span>
                                                            <span class="badge badge-pill badge-light-info mr-1" *ngIf="prn.status == 3">Half Receive</span>
                                                        </td>
                                                        <td>
                                                            <div ngbDropdown container="body">
                                                                <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                                                                  <i data-feather="more-vertical"></i>
                                                                </button>
                                                                <div ngbDropdownMenu>
                                                                    <a ngbDropdownItem (click)="downloadNon(prn.id,ndx)"><i data-feather="download" class="mr-50"></i><span>Download</span></a>
                                                                    <a ngbDropdownItem (click)="modalOpenNon(modalBasicNon,prn.id,'view')"><i data-feather="eye" class="mr-50"></i><span>View Details</span></a>
                                                                    <a ngbDropdownItem *ngIf="prn.status == 4 || prn.status == 3" (click)="modalOpenNon(modalReceiveNon,prn.id,'receive')"><i data-feather="truck" class="mr-50"></i><span>Receive</span></a>
                                                                    <a ngbDropdownItem *ngIf="prn.status == 2 || prn.status == 0 || prn.status == 4" (click)="deleteNon(prn.id)"><i data-feather="trash" class="mr-50"></i><span>Delete</span></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody *ngIf="purchaseOrderListNon?.length == 0">
                                                    <tr *ngIf="!loading">
                                                        <td colspan="8" class="no-data-available">No Data!</td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="8" class="no-data-available">
                                                            <div class="col" *ngIf="loadingNon">
                                                                <div class="text-center">
                                                                    <div class="spinner-border" role="status">
                                                                        <span class="sr-only">Loading...</span>
                                                                    </div>
                                                                    <div>
                                                                        Please Wait...
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <ngb-pagination class="mr-1 pull-right" [collectionSize]="collectionSizeNon" *ngIf="collectionSizeNon > 5" [(page)]="pageBasicTextNon" [maxSize]="3" (pageChange)="loadPageNon($event)" [pageSize]="lengthsNon" [rotate]="true"></ngb-pagination>
                                        </div>
                                    </ng-template>
                                </li>
                            </ul>
                            <div [ngbNavOutlet]="nav" class="mt-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>


<!-- Modal -->
<ng-template #modalBasic let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1" *ngIf="purchaseOrderDetails?.length != 0">#{{ purchaseOrderDetails.po_number }} </h4>
        <h4 class="modal-title" id="myModalLabel1" *ngIf="purchaseOrderDetails?.length == 0">Purchase Order Details</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col" *ngIf="purchaseOrderDetails?.length == 0">
            <div class="text-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div>
                    Please Wait...
                </div>
            </div>
        </div>
        <div class="row" *ngIf="purchaseOrderDetails?.length != 0" id="print-section">
            <div class="col-12 pt-1 overflow-auto">
                <div class="row">
                    <div class="col-12">
                        <table class="table" style="width:100%;">
                            <tbody>
                                <tr>
                                    <td style="padding:5px;width: 50%;">
                                        <h3>Supplier :</h3><br/>
                                        <b>{{ purchaseOrderDetails.manufacturer.name }}</b><br/>
                                        <b>Mobile :</b> {{ purchaseOrderDetails.manufacturer.mobile }}<br/>
                                        <b>Email :</b> {{ purchaseOrderDetails.manufacturer.email }}<br/>
                                        <b>Address : </b> {{ purchaseOrderDetails.manufacturer.address_one }}, {{ purchaseOrderDetails.manufacturer.address_two }}, {{ purchaseOrderDetails.manufacturer.address_three }} - {{ purchaseOrderDetails.manufacturer.state
                                        }}, {{ purchaseOrderDetails.manufacturer.city }}
                                        <span *ngIf="currentUser.user.is_gst_print == 1"><b>GST : </b>{{ purchaseOrderDetails.manufacturer.gst_no | uppercase }}</span>
                                    </td>
                                    <td style="padding:5px;width: 50%;">
                                        <h3>Vendor :</h3> <br/>
                                        <b>Name :</b> {{ purchaseOrderDetails.name }}<br/>
                                        <b>Mobile :</b> {{ purchaseOrderDetails.mobile }}<br/>
                                        <b>Address :</b> {{ purchaseOrderDetails.address }}
                                        <br/><br/>

                                        <b>PO Number :</b> {{ purchaseOrderDetails.po_number }}<br/>
                                        <b>PO Date :</b> {{ purchaseOrderDetails.po_date }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table class="table table-hover table-striped" style="width:100%;margin-top:15px;">
                            <thead>
                                <tr style="font-weight:bold;">
                                    <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">Sr.No.</td>
                                    <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">Product</td>
                                    <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">Price</td>
                                    <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">Qty</td>
                                    <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">Remaining Qty</td>
                                    <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">Total</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let ord of purchaseOrderDetails.order_details; let sr = index">
                                    <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">{{sr+1}}</td>
                                    <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">
                                        {{ ord.product_name }}
                                        <small><br/>{{ ord.category_name }}</small>
                                    </td>
                                    <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">{{ord.price}}</td>
                                    <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">{{ord.qty}}</td>
                                    <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">{{ord.remaining_qty}}</td>
                                    <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">{{ord.total}}</td>
                                </tr>

                            </tbody>
                        </table>
                        <table class="table" style="width:100%;border:none;">
                            <tbody>
                                <tr *ngIf="purchaseOrderDetails.remarks != ''">
                                    <td><b>Remarks : </b> {{ purchaseOrderDetails.remarks }}</td>
                                </tr>

                                <tr>
                                    <td><b>Payment Terms : </b> {{ purchaseOrderDetails.paymentCondition }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>INSTRUCTION:</b> <br>
                                        <ol>
                                            <li>GOODS MUST SUPPLY IN STANDARD PACKING IN CASE OF NON-STANDARD & DAMAGE PACKING GOODS WILL NOT BE UNLOADING. IN CASE OF URGENCY, XRICTICAL PARTS QUALITY WILL BE UNLOADED & REMAINING ITEMS QUANTITY KEEPHOLD TILL
                                                SUPPLIERS AGREE & ASSURE IMPROVEMENT IN FUTURE SUPPLIES</li>
                                            <li>DIMENSIONAL AND CHEMICAL REPORTS ARE MANDATORY ALONG WITH EACH LOT IN CASE ANY OTHER DOCS ARE NOT SPECIFIED</li>
                                            <li>ISSUE FORM "C" WHENEVER CST IS APPLICABLE</li>
                                            <li>SHALL HAVE PO NO ON EACH INOVICE OR CHALLAN NO FOR ASSEMBLED MATERIAL IF MUST BE SUPPILED IN ASSEMBLED CONDITION ONLY MATERIAL INWARD IS RESTRICTED AFTER 07:30 PM</li>
                                        </ol>

                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding:5px;background-color: #1e9dd6;text-align: center;color:rgb(194, 194, 194)">
                                        Powered By : Pro Clean
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.close('Accept click')" rippleEffect>
            Close
        </button>
        <button type="button" class="btn btn-success" printSectionId="print-section" ngxPrint><i class="fa fa-print"></i> Print</button>
    </div>
</ng-template>
<!-- / Modal -->


<!-- Modal Receive -->
<ng-template #modalReceive let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1" *ngIf="purchaseOrderReceive?.length != 0">#{{ purchaseOrderDetails.po_number }} </h4>
        <h4 class="modal-title" id="myModalLabel1" *ngIf="purchaseOrderReceive?.length == 0">Purchase Order Receive</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col" *ngIf="purchaseOrderReceive?.length == 0">
            <div class="text-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div>
                    Please Wait...
                </div>
            </div>
        </div>
        <div class="row" *ngIf="purchaseOrderReceive?.length != 0" id="print-section">
            <div class="col-12 pt-1 overflow-auto">
                <div class="row">
                    <div class="col-7">
                        <b>Vendor Name :</b> {{ purchaseOrderReceive.name }}
                    </div>
                    <div class="col-5 text-end text-right">
                        <b>PO Date :</b> {{ purchaseOrderReceive.po_date }}
                    </div>
                    <div class="col-12 mt-2">
                        <hr/>
                        <div class="row">
                            <div class="col-4"><b>Product</b></div>
                            <div class="col-2"><b>Price</b></div>
                            <div class="col-2 text-center"><b>Qty</b></div>
                            <div class="col-2 text-center"><b>Receive Qty</b></div>
                            <div class="col-2 text-center"><b>Remaining Qty</b></div>
                        </div>
                        <hr class="m-0 p-0" />
                        <hr/>
                    </div>
                    <div class="col-12" *ngFor="let orderProduct of purchaseOrderReceive.order_details; let sr = index">
                        <div class="row">
                            <div class="col-4">
                                {{ orderProduct.product_name }}
                                <small><br/>{{ orderProduct.category_name }}</small>
                            </div>

                            <div class="col-2 text-end">
                                {{orderProduct.price}}
                            </div>
                            <div class="col-2 text-center">
                                {{orderProduct.qty}}
                            </div>
                            <div class="col-2 text-center">
                                <core-touchspin [numberValue]="0" size="sm" [stepValue]="1" [minValue]="0" [maxValue]="orderProduct.remaining_qty" (onChange)="countChange($event,sr)"></core-touchspin>
                            </div>
                            <div class="col-2 text-center">
                                {{orderProduct.remaining_qty_new}}
                            </div>
                        </div>
                        <hr/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.close('Accept click')" rippleEffect>
            Close
        </button>
        <button type="button" class="btn btn-info" (click)="onReceiveSave()"><i class="fa fa-save"></i> Save</button>
    </div>
</ng-template>
<!-- / Modal Receive -->

<!-- Modal Non Product -->
<ng-template #modalBasicNon let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1" *ngIf="purchaseOrderDetails?.length != 0">#{{ purchaseOrderDetailsNon.po_number }} </h4>
        <h4 class="modal-title" id="myModalLabel1" *ngIf="purchaseOrderDetailsNon?.length == 0">Non Product Purchase Order Details</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col" *ngIf="purchaseOrderDetailsNon?.length == 0">
            <div class="text-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div>
                    Please Wait...
                </div>
            </div>
        </div>
        <div class="row" *ngIf="purchaseOrderDetailsNon?.length != 0" id="print-section">
            <div class="col-12 pt-1 overflow-auto">
                <div class="row">
                    <div class="col-12">
                        <table class="table" style="width:100%;">
                            <tbody>
                                <tr>
                                    <td style="padding:5px;width: 50%;">
                                        <h3>Supplier :</h3><br/>
                                        <b>{{ purchaseOrderDetailsNon.manufacturer.name }}</b><br/>
                                        <b>Mobile :</b> {{ purchaseOrderDetailsNon.manufacturer.mobile }}<br/>
                                        <b>Email :</b> {{ purchaseOrderDetailsNon.manufacturer.email }}<br/>
                                        <b>Address : </b> {{ purchaseOrderDetailsNon.manufacturer.address_one }}, {{ purchaseOrderDetailsNon.manufacturer.address_two }}, {{ purchaseOrderDetailsNon.manufacturer.address_three }} - {{ purchaseOrderDetailsNon.manufacturer.state
                                        }}, {{ purchaseOrderDetailsNon.manufacturer.city }}
                                        <span *ngIf="currentUser.user.is_gst_print == 1"><b>GST : </b>{{ purchaseOrderDetailsNon.manufacturer.gst_no | uppercase }}</span>
                                    </td>
                                    <td style="padding:5px;width: 50%;">
                                        <h3>Vendor :</h3> <br/>
                                        <b>Name :</b> {{ purchaseOrderDetailsNon.name }}<br/>
                                        <b>Mobile :</b> {{ purchaseOrderDetailsNon.mobile }}<br/>
                                        <b>Address :</b> {{ purchaseOrderDetailsNon.address }}
                                        <br/><br/>

                                        <b>PO Number :</b> {{ purchaseOrderDetailsNon.po_number }}<br/>
                                        <b>PO Date :</b> {{ purchaseOrderDetailsNon.po_date }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table class="table table-hover table-striped" style="width:100%;margin-top:15px;">
                            <thead>
                                <tr style="font-weight:bold;">
                                    <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">Sr.No.</td>
                                    <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">Product</td>
                                    <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">Price</td>
                                    <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">Qty</td>
                                    <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">Remaining Qty</td>
                                    <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">Total</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let ord of purchaseOrderDetailsNon.order_details; let sr = index">
                                    <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">{{sr+1}}</td>
                                    <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">
                                        {{ ord.product_name }}
                                    </td>
                                    <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">{{ord.price}}</td>
                                    <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">{{ord.qty}}</td>
                                    <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">{{ord.remaining_qty}}</td>
                                    <td style="border-bottom: 1px dashed #ebe9f1;padding:5px;">{{ord.total}}</td>
                                </tr>

                            </tbody>
                        </table>
                        <table class="table" style="width:100%;border:none;">
                            <tbody>
                                <tr *ngIf="purchaseOrderDetailsNon.remarks != ''">
                                    <td><b>Remarks : </b> {{ purchaseOrderDetailsNon.remarks }}</td>
                                </tr>

                                <tr>
                                    <td><b>Payment Terms : </b> {{ purchaseOrderDetailsNon.paymentCondition }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>INSTRUCTION:</b> <br>
                                        <ol>
                                            <li>GOODS MUST SUPPLY IN STANDARD PACKING IN CASE OF NON-STANDARD & DAMAGE PACKING GOODS WILL NOT BE UNLOADING. IN CASE OF URGENCY, XRICTICAL PARTS QUALITY WILL BE UNLOADED & REMAINING ITEMS QUANTITY KEEP HOLD TILL
                                                SUPPLIERS AGREE & ASSURE IMPROVEMENT IN FUTURE SUPPLIES</li>
                                            <li>DIMENSIONAL AND CHEMICAL REPORTS ARE MANDATORY ALONG WITH EACH LOT IN CASE ANY OTHER DOCS ARE NOT SPECIFIED</li>
                                            <li>ISSUE FORM "C" WHENEVER CST IS APPLICABLE</li>
                                            <li>SHALL HAVE PO NO ON EACH INVOICE OR CHALLAN NO FOR ASSEMBLED MATERIAL IF MUST BE SUPPLIED IN ASSEMBLED CONDITION ONLY MATERIAL INWARD IS RESTRICTED AFTER 07:30 PM</li>
                                        </ol>

                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding:5px;background-color: #1e9dd6;text-align: center;color:rgb(194, 194, 194)">
                                        Powered By : Pro Clean
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.close('Accept click')" rippleEffect>
            Close
        </button>
        <button type="button" class="btn btn-success" printSectionId="print-section" ngxPrint><i class="fa fa-print"></i> Print</button>
    </div>
</ng-template>
<!-- / Modal Non Product-->

<!-- Modal No Product Receive -->
<ng-template #modalReceiveNon let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1" *ngIf="purchaseOrderReceiveNon?.length != 0">#{{ purchaseOrderDetailsNon.po_number }} </h4>
        <h4 class="modal-title" id="myModalLabel1" *ngIf="purchaseOrderReceiveNon?.length == 0">Non Product Purchase Order Receive</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="col" *ngIf="purchaseOrderReceiveNon?.length == 0">
            <div class="text-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div>
                    Please Wait...
                </div>
            </div>
        </div>
        <div class="row" *ngIf="purchaseOrderReceiveNon?.length != 0" id="print-section">
            <div class="col-12 pt-1 overflow-auto">
                <div class="row">
                    <div class="col-7">
                        <b>Vendor Name :</b> {{ purchaseOrderReceiveNon.name }}
                    </div>
                    <div class="col-5 text-end text-right">
                        <b>PO Date :</b> {{ purchaseOrderReceiveNon.po_date }}
                    </div>
                    <div class="col-12 mt-2">
                        <hr/>
                        <div class="row">
                            <div class="col-4"><b>Product</b></div>
                            <div class="col-2"><b>Price</b></div>
                            <div class="col-2 text-center"><b>Qty</b></div>
                            <div class="col-2 text-center"><b>Receive Qty</b></div>
                            <div class="col-2 text-center"><b>Remaining Qty</b></div>
                        </div>
                        <hr class="m-0 p-0" />
                        <hr/>
                    </div>
                    <div class="col-12" *ngFor="let or of purchaseOrderReceiveNon.order_details; let sr = index">
                        <div class="row">
                            <div class="col-4">
                                {{ or.product_name }}
                                <small><br/>{{ or.category_name }}</small>
                            </div>

                            <div class="col-2 text-end">
                                {{or.price}}
                            </div>
                            <div class="col-2 text-center">
                                {{or.qty}}
                            </div>
                            <div class="col-2 text-center">
                                <core-touchspin [numberValue]="0" size="sm" [stepValue]="1" [minValue]="0" [maxValue]="or.remaining_qty" (onChange)="countChangeNon($event,sr)"></core-touchspin>
                            </div>
                            <div class="col-2 text-center">
                                {{or.remaining_qty_new}}
                            </div>
                        </div>
                        <hr/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.close('Accept click')" rippleEffect>
            Close
        </button>
        <button type="button" class="btn btn-info" (click)="onReceiveSaveNon()"><i class="fa fa-save"></i> Save</button>
    </div>
</ng-template>
<!-- / Modal No Product Receive -->


<!-- New User Sidebar -->
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="new-sidebar" overlayClass="modal-backdrop">
    <div class="slideout-content">
        <!-- Modal to add new starts-->
        <div class="modalsd modal-slide-in sdfade new-modal" id="modals-slide-in">
            <div class="modal-dialog">
                <form class="add-new modal-content pt-0" [formGroup]="newFormFilter" (ngSubmit)="NonToggleSidebar ? onSubmitNon() : onSubmit()">
                    <button type="button" *ngIf="NonToggleSidebar" class="close" data-dismiss="modal" aria-label="Close" (click)="toggleSidebarNon('new-sidebar')">
                      ×
                    </button>
                    <button type="button" *ngIf="!NonToggleSidebar" class="close" data-dismiss="modal" aria-label="Close" (click)="toggleSidebar('new-sidebar')">
                        ×
                    </button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title" id="exampleModalLabel" *ngIf="NonToggleSidebar">Non Product PO Filter</h5>
                        <h5 class="modal-title" id="exampleModalLabel" *ngIf="!NonToggleSidebar">Filter</h5>
                    </div>
                    <div class="modal-body flex-grow-1 ">
                        <div class="form-group">
                            <label class="form-label">From Date</label>
                            <input type="date" formControlName="start_date" id="start_date" placeholder="dd-mm-yyyy" class="form-control">
                        </div>
                        <div class="form-group">
                            <label class="form-label">To Date</label>
                            <input type="date" formControlName="end_date" id="end_date" placeholder="dd-mm-yyyy" class="form-control">
                        </div>

                        <div class="form-group">
                            <label class="form-label">Status</label>
                            <ng-select formControlName="order_status" multiple="true" aria-describedby="order_status">
                                <ng-option value="">All</ng-option>
                                <ng-option [value]="0" selected>Processing</ng-option>
                                <ng-option [value]="1">Received</ng-option>
                                <ng-option [value]="2">Canceled</ng-option>
                                <ng-option [value]="3">Half Receive</ng-option>
                                <ng-option [value]="4">Approved</ng-option>
                            </ng-select>
                        </div>

                        <div class="form-group">
                            <label class="form-label">Vendor Name</label>
                            <ng-select [items]="vendorDropDown" [loading]="selectBasicLoading" bindValue="id" bindLabel="name" placeholder="Select Vendor" formControlName="vendor_id"> </ng-select>
                        </div>

                        <div class="form-group">
                            <button type="submit" class="btn btn-primary mr-1 " rippleEffect [disabled]="loading"> <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1 "></span>Go</button>
                            <button type="reset" *ngIf="NonToggleSidebar" class="btn btn-primary mr-1 " (click)="reloadListNon()" (click)="toggleSidebarNon('new-sidebar')" rippleEffect [disabled]="loading"> 
                                <span *ngIf="loading " class="spinner-border spinner-border-sm mr-1 "></span>                                
                                <i data-feather="refresh-cw" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                <span class="d-none d-sm-inline-block">Reset</span>                                
                            </button>
                            <button type="reset" *ngIf="!NonToggleSidebar" class="btn btn-primary mr-1 " (click)="reloadList()" (click)="toggleSidebar('new-sidebar')" rippleEffect [disabled]="loading"> 
                                <span *ngIf="loading " class="spinner-border spinner-border-sm mr-1 "></span>                                
                                <i data-feather="refresh-cw" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                <span class="d-none d-sm-inline-block">Reset</span>                                
                            </button>
                            <a class="btn btn-secondary" *ngIf="NonToggleSidebar" (click)="toggleSidebarNon('new-sidebar')" rippleEffect>
                                <i data-feather="x" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                <span class="d-none d-sm-inline-block">Cancel</span>
                            </a>
                            <a class="btn btn-secondary" *ngIf="!NonToggleSidebar" (click)="toggleSidebar('new-sidebar')" rippleEffect>
                                <i data-feather="x" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                <span class="d-none d-sm-inline-block">Cancel</span>
                            </a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- Modal to add new Ends-->
    </div>
</core-sidebar>
<!--/ New User Sidebar -->