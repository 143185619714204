import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { VendorCategoryService } from 'app/_services/vendor-category.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'app/auth/service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-vendor-categories',
  templateUrl: './vendor-categories.component.html',
  styleUrls: ['./vendor-categories.component.scss']
})
export class VendorCategoriesComponent implements OnInit {

  public vendorCategoryList: any = [];
  public start: any = 0;
  public page: any = 0;

  public loading = false;
  public error = '';
  public newForm: FormGroup;
  public submitted = false;
  public loadingForm = false;
  public rowMaterialCategoryId = 0;

  public sidebarToggleRef = false;
  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 15;
  public progressMainTable: boolean = false;
  public addMode: boolean = true;

  constructor(private _router: Router, private _authenticationService: AuthenticationService, private _toastrService: ToastrService, private http: HttpClient, private vendorCategoryService: VendorCategoryService, private _coreSidebarService: CoreSidebarService, private modalService: NgbModal, private _formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.loading = true;
    this.categoryList(this.start, this.lengths);

    this.newForm = this._formBuilder.group({
      rowmaterialcategoryname: ['', Validators.required],
      rowmaterialcategorydescription: ['']
    });

  }

  get f() {
    return this.newForm.controls;
  }

  categoryList(my_start: any, my_lengths: any) {

    this.vendorCategoryService.getData(my_start, my_lengths).subscribe(
      response => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.vendorCategoryList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  reloadList() {
    this.progressMainTable = true;
    this.categoryList(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.categoryList(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.categoryList(this.start, this.lengths);
  }


  delete(id: any) {
    const me = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(function (result) {
      if (result.value) {
        me.vendorCategoryService.delete(id).subscribe(
          response => {
            if (response.status) {
              me.progressMainTable = true;
              me.categoryList(me.page, me.lengths);
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Product Deleted Successfully',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            } else {
              Swal.fire({
                icon: 'warning',
                title: 'Opps!',
                text: response.message,
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
          }
        );
      }
    });
  }

  onSubmit() {
    this.submitted = true;

    if (this.newForm.invalid) {
      this.error = '';
    }
    else {
      this.error = '';
      this.loadingForm = true;
      this.vendorCategoryService.add(this.f.rowmaterialcategoryname.value, this.f.rowmaterialcategorydescription.value, this.rowMaterialCategoryId).subscribe(
        data => {
          this.loadingForm = false;
          if (data.status) {
            this.submitted = false;
            this.toggleSidebar('new-sidebar');
            this.newForm.reset();
            this._toastrService.clear();
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            this.progressMainTable = true;
            this.categoryList(this.page, this.lengths);
          }
          else {
            if (data.message.category_name) {
              this.error = data.message.category_name[0];
            }
            else {
              this.error = data.message;
            }
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });

    }
  }

  /**
 * Toggle the sidebar
 *
 * @param name
 */
  toggleSidebar(name, id: number = 0, index: any = null): void {
    this.submitted = false;
    this.error = '';
    this.loadingForm = false;
    this.addMode = true;
    this.rowMaterialCategoryId = 0;

    if (id != 0 && index != null) {
      this.addMode = false;
      this.f.rowmaterialcategoryname.setValue(this.vendorCategoryList[index].category_name);
      this.f.rowmaterialcategorydescription.setValue(this.vendorCategoryList[index].description);
      this.rowMaterialCategoryId = id;
    }

    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

}
